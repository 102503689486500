import React, { useEffect, useState } from "react"
import styles from '../css/slideImages.module.css'

export default function SlideImagenes({
    imagenesArray = [],
    titlesArray = []
}) {
  const [checkedArray, setCheckedArray] = useState([]);

  const initialiceData = () => {
    let initialArray = Array(imagenesArray.length).fill(false)
    if(imagenesArray.length > 1){
      initialArray[0] = true
    }
    setCheckedArray(initialArray)
  }
  
  useState(()=>{
    if(titlesArray == undefined){
        titlesArray = []
    }
    if(imagenesArray == undefined){
        imagenesArray = []
    }
    initialiceData()
  }, [imagenesArray])

  const changeStateImages = (image) => {
    let initialArray = Array(imagenesArray.length).fill(false)
    initialArray[image] = true
    setCheckedArray(initialArray)
  }

  const changeToImage = (index) => {
    changeStateImages(index)
  }

  return (
    <div>
      <div className={styles.slideshowcontainer}>
        {
          imagenesArray.map((img, index) => {
            return <>
              {
                checkedArray[index] &&
                <div className={styles.fade}>
                <div className={styles.numbertext}>{ titlesArray[index] }</div>
                <img src={img} style={{height:'350px', display:'block', marginLeft: 'auto', marginRight: 'auto'}} />
                <div className={styles.text}>
                  <a className={styles.link} href={img} target="_blank" download>Descargar</a>
                </div>
                </div>
              }
            </>
          })
        }
      </div>
      <br />

      <div style={{textAlign:'center'}}>
        {
          titlesArray.map((title, index) => {
            return <>
              <span className={checkedArray[index] ? styles.active : styles.dot} onClick={()=>{changeToImage(index)}}>{title}</span> 
            </>
          })
        }
      </div>
    </div>
  );
}
