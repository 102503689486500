import getColors from "../../../components/chart/colors"

const PolizaPieChartData =  (polizas) => {
  const colors = getColors()
  let labels = []
  let datasets = []  
  let grupos = []
  let cantidades = []
  let colores = []
  let cont = 0
  const setData = (data) =>{
    data.forEach(element => {
      labels.push(element.ciudad)
      cantidades.push(element.cantidad)
      colores.push(colors[cont])
      cont++
      if(cont > 8)
        cont = 0
    })
    
    return {
      labels: labels,
      datasets: [
        {
          label: "Cantidad de Solicitudes",
          data: cantidades,
          backgroundColor: colores,
          borderColor: colores,
          borderWidth: 1,
        }
      ]
    }
  }
  return setData(polizas)
}
export default PolizaPieChartData