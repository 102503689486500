import React, { useEffect, useState } from "react"
import Footer from "../ui/footer"
import Header from "../ui/header"
import MenuBar from "../ui/navbar"
import Grid from '@mui/material/Grid'
import { MuiThemeProvider } from '@material-ui/core/styles'
// import { ThemeProvider } from "@mui/material"
import {colors, darktheme} from '../common/themes'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'
// TODO: Descomentar cuando ya se tenga el certificado
import { verifyNotification } from '../../firebase';

const drawerWidth = 240;

const Layout = (
  props
) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [cookie, setCookie] = useCookies()
  const navigate = useNavigate();
  const location = useLocation();
  const [isTokenFound, setTokenFound] = useState(false);

  const { window, children } = props;

  const menuHadle = () => {
    console.log("aaaa")
    setMenuOpen(!menuOpen)
  }

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    console.log("Token de inicio de sesion....",cookie.token)
    if(cookie.token == "undefined" || cookie.token == undefined){
      navigate("/");
    }
    verifyNotification(setTokenFound)
    console.log("Esta iniciado???")
    console.log(!cookie.iniciado)
    const pathName = location.pathname;    
    if(cookie.iniciado == "false" && pathName != "/cambio-contrasenia"){
      setCookie("path", pathName)
      navigate("/cambio-contrasenia")
    } 
  })
  return <>
    <MuiThemeProvider theme={darktheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header title={'Hedaer'} externalAction={menuHadle} />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={menuOpen}
            onClose={menuHadle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            <MenuBar children={'Menuuuuuu'}></MenuBar>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            <MenuBar children={'Menuuuuuu'}></MenuBar>
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar /> 
          <Grid container direction="column" justify="flex-end">
            {children}
          </Grid>
        </Box>
        <Footer children={''} />
      </Box>
    </MuiThemeProvider>
  </>
}

export default Layout