import { 
  ListSubheader 
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Check from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Cookies from 'universal-cookie';


const MenuBar = ({
  children
}) => {
  const [menu, setMenu] = useState([])
  const navigate = useNavigate();

  const setMenuRequest = async () => {
    let cookeando = new Cookies()
    // let provider = new CookiesProvider()
    let menus = cookeando.get("menus")

    let menuLocal = []
    const principales = menus.filter(ar => ar.fields.submenu == null)
    for(let i = 0; i < principales.length; i++){
      const secundarios = menus.filter(ar => ar.fields.submenu == principales[i].pk)
      let menuObj = {
        id: principales[i].pk,
        name : principales[i].fields.nombre,
        path: principales[i].fields.path,
        submenu : []
      }
      for(let j = 0; j < secundarios.length; j++){
        let submenuObj = {
          id: secundarios[j].pk,
          name: secundarios[j].fields.nombre,
          path: secundarios[j].fields.path,
          submenu: undefined,
        }
        menuObj.submenu.push(submenuObj)
      }
      menuLocal.push(menuObj)
    }
    setMenu(menuLocal)
  }

  const handleClick = (path) => {
    console.log(path != "null")
    if(path != null && path != "null")
      navigate(path)
  }


  useEffect(() => {
    setMenuRequest()
  }, [])

  return (
    <div>
      <Toolbar />
      {
        menu.map((men, findex) => [
          <Divider />,
          <List key={`menu-${findex}`}>
            <ListItem key={`list-item-${men.name}-${findex}`} disablePadding>
              <ListItemButton onClick={()=> {handleClick(men.path)}} key={`item-button-${men.name}-${findex}`}>
                <ListItemText primary={men.name} key={`list-item-index-${men.name}-${findex}`} />
              </ListItemButton>
            </ListItem>
          </List>,
          <>
            {
              menu.submenu ? <Divider /> :<></> 
            }
          </>,
          <List key={`submenu-${findex}`}>
            {men.submenu?.map((submen, index) => (
              <ListItem key={`submen-list-item-${submen.name}-${findex}-${index}`} disablePadding>
                <ListItemButton key={`click-${findex}-${index}`} onClick={()=> {handleClick(submen.path)}}>
                  <ListItemIcon key={`submenu-icon-${men.name}-${findex}`}>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={submen.name} key={`submenu-item-text-${men.name}-${findex}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>,
        ])
      }
    </div>
  )
}
export default MenuBar


// import * as React from 'react';
// import ListSubheader from '@mui/material/ListSubheader';
// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Collapse from '@mui/material/Collapse';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import SendIcon from '@mui/icons-material/Send';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import StarBorder from '@mui/icons-material/StarBorder';

// export default function NestedList() {
//   const [open, setOpen] = React.useState(true);

//   const handleClick = () => {
//     setOpen(!open);
//   };

//   return (
//     <List
//       sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
//       component="nav"
//       aria-labelledby="nested-list-subheader"
//       subheader={
//         <ListSubheader component="div" id="nested-list-subheader">
//           Nested List Items
//         </ListSubheader>
//       }
//     >
//       <ListItemButton>
//         <ListItemIcon>
//           <SendIcon />
//         </ListItemIcon>
//         <ListItemText primary="Sent mail" />
//       </ListItemButton>
//       <ListItemButton>
//         <ListItemIcon>
//           <DraftsIcon />
//         </ListItemIcon>
//         <ListItemText primary="Drafts" />
//       </ListItemButton>
//       <ListItemButton onClick={handleClick}>
//         <ListItemIcon>
//           <InboxIcon />
//         </ListItemIcon>
//         <ListItemText primary="Inbox" />
//         {open ? <ExpandLess /> : <ExpandMore />}
//       </ListItemButton>
//       <Collapse in={open} timeout="auto" unmountOnExit>
//         <List component="div" disablePadding>
//           <ListItemButton sx={{ pl: 4 }}>
//             <ListItemIcon>
//               <StarBorder />
//             </ListItemIcon>
//             <ListItemText primary="Starred" />
//           </ListItemButton>
//         </List>
//       </Collapse>
//     </List>
//   );
// }