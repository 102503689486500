import React from "react";
import { Grid } from "@mui/material"
import { Pie } from 'react-chartjs-2';


function PieChart({ chartData, legends = null, subTitle, title }) {
  return (
    <Grid container direction={"column"} rowSpacing={2} className="chart-container">
      <Grid item>
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      <Pie
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: subTitle
            },
            legend: {
              display: true
            }
          }
        }}
      />
      </Grid>
      <Grid item>
        { legends != null &&
          <Grid id={`contenedor`} container direction={"row"} spacing={2} >
            {legends.map((legend) => (
              <Grid item>
                <Grid container direction={"row"} spacing={1}>
                  <Grid item style={{backgroundColor : legend.color}}>&nbsp; &nbsp; &nbsp;</Grid>
                  <Grid item>{legend.name}</Grid>
                </Grid>
              </Grid>
            ))
            }
          </Grid>
        }
      </Grid>
    </Grid>
  );
}

export default PieChart;