import { Grid, Typography, Button, TextField, } from "@mui/material"
import { Box } from "@mui/system"
import 'dayjs/locale/es';
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Alarm';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { Service } from "../../components/services/service";
import { TemporalService } from "../../components/services/temporalService";
import LinearProgress from '@mui/material/LinearProgress';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Input } from '@mui/material';
import { FormHelperText } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Option from '@mui/joy/Option';
import { StyledEngineProvider, CssVarsProvider } from '@mui/joy/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import dayjs, { Dayjs } from 'dayjs';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Cobros = () => {
  const [usersData, setUsersData] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [issuesData, setIssuesData] = useState([])
  const [departmentsData, setDepartmentsData] = useState([])
  const [tableData, setTableData] = useState([])
  const [emmitError, setEmmitError] = useState()
  const [loading, setLoading] = useState(false)
  const [polizaData, setPolizaData] = useState()
  const [modalDetailPoliza, setModalDetailPoliza] = useState(false)
  const [ fechaInicio, setFechaInicio] = useState('')
  const [ fechaFinalizacion, setFechaFinalizacion] = useState('')
  const [ departamentoCobro, setDepartamentoCobro] = useState('')
  const [ datePickerInicioValue, setDatePickerInicioValue ] = useState(dayjs(''))
  const [ datePickerFinalValue, setDatePickerFinalValue ] = useState(dayjs(''))
  const [ errorRequest, setErrorRequest] = useState('')
  const [ showError, setShowError ] = useState(false)
  const [ showSuccess, setShowSuccess ] = useState(false)
  const [ errorFechaInicio, setErrorFechaInicio] = useState(false)
  const [ errorFechaFinalizacion, setErrorFechaFinalizacion] = useState(false)
  const [ errorCiudad, setErrorCiudad] = useState(false)
  const [ emmitMessage, setEmmitMessage] = useState(false)
  
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectDepartment = (event) => {
    setDepartamentoCobro(event.target.value)
  }

  const setIssuesEvent = () => {
    const start = page * rowsPerPage
    const final = (page + 1) * rowsPerPage
    setTableData(issuesData.slice(start, final))
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseModal = async () => {
    setModalDetailPoliza(false)
  }

  const handleDetailPolizaViewer = async(polizaDataModal) => {
    setPolizaData(polizaDataModal)
    await timeOut(1000)
    setModalDetailPoliza(true)
  }
  const handleCobro = async () => {
    let sw = true
    const valFechaInicio = fechaInicio == ""
    setErrorFechaInicio(valFechaInicio)
    const valFechaFinalizacion = fechaFinalizacion == ""
    setErrorFechaFinalizacion(valFechaFinalizacion)
    const valCiudad = departamentoCobro === ""
    setErrorCiudad(valCiudad)
    sw = sw && !valFechaInicio && !valFechaFinalizacion && !valCiudad

    if(sw)
      cobroRequest()
      
  }

  function timeOut(delay){
    return new Promise(res => setTimeout(res, delay))
  }
  
  const columns = [
    { id: 'estado', label: 'Estado' }, //minWidth: 170
    { id: 'vigencia_inicio', label: 'Fecha de Inicio' },
    { id: 'vigencia_final', label: 'Fecha de Finalizacion' },
    { id: 'nro_poliza', label: 'Nro de Poliza' },
    { id: 'contract_alianza', label: 'Nro Contrato' },
    { id: 'error', label: 'Error' },
  ]

  const handleActionEmmit = async (data) => {
    emmitRequest(data)
  }
  const handleClose = () => {
    setShowError(false)
    setShowSuccess(false)
  }
  

  const cobroRequest = async () => {
    setLoading(true)
    try{
      const param = {
        fecha_inicio : fechaInicio,
        fecha_final : fechaFinalizacion,
        ciudad_id : departamentoCobro
      }
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "alianza/actualizar_cobros",
        headers: null,
        body: null,
        param: param,
      })
      console.log("RESULT ", result)
      console.log("Estado del resultado ", result.data.status)
      if(result.data.status == 1){
        console.log()
        setShowError(false)
        setErrorRequest('')
        setShowSuccess(true)
        setEmmitMessage(result.data.message)
        setTableData(result.data.cobros)
      }else{
        setShowError(true)
        setErrorRequest(result.data.message)
        setShowSuccess(false)
        setEmmitMessage("")
        setTableData([])
      }
      
      // setDepartmentsData(result.data)
    }catch(e){
      setShowError(true)
      setErrorRequest(e.getMessage())
    }finally{
      setLoading(false)
    }
  }
  const departmentRequest = async () => {
    setLoading(true)
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "ciudad/list",
        headers: null,
        body: null,
        param: null,
      })
      setDepartmentsData(result.data)
    }catch(e){

    }finally{
      setLoading(false)
    }
  }

  const issueRequest = async () => {
    setLoading(true)
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "poliza/list",
        headers: null,
        body: null,
        param: null,
      })
      
      setIssuesData(result.data)
    }catch(e){

    }finally{
      setLoading(false)
    }
  }

  const emmitRequest = async (data) => {
    setLoading(true)
    try{
      const response = await TemporalService.sessionRequest({
        method: "get",
        url: `alianza/emitir/?id_poliza=${data.id}`,
        headers: null,
        body: null,
        param: null,
      })
      if(response.data.detail.lstErrors.length > 0){
        let error = ""
        response.data.detail.lstErrors.forEach(element => {
          error += " " + element.sMessaged
        });
        setEmmitError(error)
        alert(error)
      }else{
        issueRequest()
      }
      
    }catch(e){
      alert("Error Inesperado")
      console.log(e)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=> {
    setIssuesEvent()
  }, [issuesData, page, rowsPerPage])

  useEffect(() => {
    departmentRequest()
  }, [])
  
  return (
    <Grid container direction="column" rowSpacing={3}>
      {/* <Grid item xs={12}>
        <Typography align="left">Usuario:</Typography>
      </Grid> */}
      <Box
        sx={{
          width: 40,
          height: 40,
        }}
      />
      <Typography component={'span'} variant="h3" align="left">
        Actualización de Cobros
      </Typography>
      <Grid container direction="row" columnSpacing={3} alignItems={'center'}>
        <Grid item xs={3} md={3}>
          <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              label="Fecha de Inicio"
              value={datePickerInicioValue}
              key="fecha-inicio"
              dateFormat="DD/MM/YYYY"
              aria-describedby="helper-inicio"
              onChange={(newFecha) => {
                setFechaInicio(newFecha.format('DD-MM-YYYY'))
                setDatePickerInicioValue(newFecha)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <FormHelperText id="helper-inicio" error={errorFechaInicio}>Fecha requerida</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item  xs={3} md={3}>
          <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              label="Fecha Finalización"
              value={datePickerFinalValue}
              key="fecha-finalizacion"
              aria-describedby="helper-finalizacion"
              onChange={(newFecha) => {
                setFechaFinalizacion(newFecha.format('DD-MM-YYYY'))
                setDatePickerFinalValue(newFecha)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <FormHelperText id="helper-finalizacion" error={errorFechaFinalizacion}>Fecha requerida</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4} md={4}>
          <FormControl fullWidth>
            <InputLabel id="select-departmemts-label">Ciudad</InputLabel>
            <Select
              labelId="select-departmemts-label"
              id="select-departmemts"
              key="select-departmemts"
              label="Ciudad"
              aria-describedby="helper-ciudad"
              value={departamentoCobro}
              onChange={handleSelectDepartment}
            >
              {
                departmentsData.map((dept, index) => {
                  return <MenuItem key={`ciudad-${index}-${dept.id}`} value={dept.id}>{dept.nombre_ciudad}</MenuItem>
                })
              }
            </Select>
            <FormHelperText id="helper-ciudad" error={errorCiudad}>La ciudad es requerida</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={2} md={2} >
            <Button key="btn-cobrar" variant="contained" onClick={handleCobro}>Registrar</Button>
        </Grid>
      </Grid>

      <Grid item xs={12} >
        { 
          <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {errorRequest}
            </Alert>
          </Snackbar>
        }
        {
          <Snackbar open={showSuccess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              {emmitMessage}
            </Alert>
          </Snackbar>
        }
        
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          { loading && <LinearProgress /> }
          <TableContainer >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography component={'span'} variant="columnTittle">
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={`${data.id}-${index}-row`}>
                        <TableCell key={`${data.id}-${index}-poliza-estado`}>
                          {data.poliza.estado_revision }
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-poliza-fecha-inicio`}>
                          {(new Date(data.poliza.vigencia_inicio)).toLocaleDateString("en-GB")}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-poliza-vigencia-final`}>
                          {(new Date(data.poliza.vigencia_final)).toLocaleDateString("en-GB")}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-poliza-nro-poliza`}>
                          {data.poliza.nro_poliza }
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-poliza-nro-contrato`}>
                          {data.poliza.contract_alianza  }
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-error`}>
                          {data.error}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={issuesData.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Filas por pagina:"
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}
export default Cobros
