import React from "react"

const Footer = ({
  children
}) => {
  return (
    <footer>
      {children}
    </footer>
  )
}
export default Footer