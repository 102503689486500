import getColors from "../../../components/chart/colors"
const meses = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
}

const PolizaBarChartData =  (polizas) => {
  const colors = getColors()
  let labels = []
  let datasets = []  
  let grupos = []
  let cantidades = []
  const setData = (data) =>{
    data.forEach(element => {
      if( labels.indexOf(element.mes + "/" + element.anio) == -1 ){
        labels.push(element.mes + "/" + element.anio)
      }
      if(grupos.indexOf(element.estado_revision) == -1){
        grupos.push(element.estado_revision)
      }
    })

    labels.forEach(labElement => {
      const filtrado = data.filter(element => {
        return labElement == element.mes + "/" + element.anio
      });
      grupos.forEach(gpElement => {
        if(!cantidades[gpElement]){
          cantidades[gpElement] = []
        }
        const filtrado2 = filtrado.filter(elem3 => {
          return elem3.estado_revision == gpElement
        })

        if(filtrado2.length > 0){
          cantidades[gpElement].push(filtrado2[0].cantidad)
        }else{
          cantidades[gpElement].push(0)
        }
      })
    })
    let countColor = 0
    grupos.forEach((elem, index) => {
      datasets.push({
        label: elem,
        backgroundColor: colors[countColor],
        borderColor: colors[countColor],
        borderWidth: 1,
        hoverBackgroundColor: colors[countColor],
        hoverBorderColor: colors[countColor],
        data: cantidades[elem]
      })
      countColor++
      if(countColor == colors.length ){
        countColor = 0
      }
    })
    
    return {
      labels: labels,
      datasets: datasets
    }
  }
  return setData(polizas)
}
export default PolizaBarChartData