import { Grid, Typography, Button, TextField, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TemporalService } from "../../../components/services/temporalService";
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EditarCiudadModal = ({
  statusModal,
  ciudadData,
  handleClose,
  handleEmmit,
}) => {
  const [rol, setRol] = React.useState(ciudadData?.roles == undefined ? '' : ciudadData?.roles[0])
  const [username, setUsername] = React.useState()
  const [id, setId] = React.useState((ciudadData == undefined ? 0 : ciudadData?.id))
  const [ isLoading, setIsLoading] = useState(false)
  const [estado, setEstado] = React.useState((ciudadData == undefined ? '' : ciudadData?.estado))
  const [roles, setRoles] = useState([])
  const [datePickerFinalValue, setDatePickerFinalValue] = useState()
  const [fechaNacimiento, setFechaNacimiento] = useState()
  const [nombre, setNombre] = useState(ciudadData?.nombre)
  const [paterno, setPaterno] = useState(ciudadData?.apellido_paterno)
  const [materno, setMaterno] = useState(ciudadData?.apellido_materno)
  const [usuario, setUsuario] = useState(ciudadData?.usuario?.name)
  const [documentoIdentidad, setDocumentoIdentidad] = useState(ciudadData?.numero_documento)
  const [extension, setExtension] = useState(ciudadData?.extension)
  const [celular, setCelular] = useState(ciudadData?.nro_celular)
  const [flagEdicionCreacion, setFlagEdicionCreacion] = useState(false)
  const [idUsuario, setIdUsuario] = useState()

  const [nombreCiudad, setNombreCiudad] = useState()
  const [idAlianzaCiudad, setIdAlianzaCiudad] = useState()
  const [usuarioAlianza, setUsuarioAlianza] = useState()
  const [passwordAlianza, setPasswordAlianza] = useState()
  const [polizaAlianza1, setPolizaAlianza1] = useState()
  const [polizaAlianza2, setPolizaAlianza2] = useState()
  const [polizaAlianza3, setPolizaAlianza3] = useState()
  const [idCiudad, setIdCiudad] = useState()



  const descriptionElementRef = React.useRef(null);

  const saveUserRequest = async () => {
    return true
  }
  const handleSave = async () => {
    updateCiudadRequest()
  }
  const handleSelectRol = async (event) => {
    console.log(event.target)
    
    setRol(event.target.value)
    if(!ciudadData)
      ciudadData = {}
    ciudadData.rol = event.target.value
  }

  function timeOut(delay){
    return new Promise(res => setTimeout(res, delay))
  }

  const columns = [
    { id: 'nro', label: 'Nro.' }, //minWidth: 170
    { id: 'name', label: 'Nombre Completo' },
    { id: 'rol', label: 'Rol' },
    { id: 'actions', label: 'Acciones' },
  ]

  const handleActionUser = async (user) => {
    console.log(user)
    alert("Hello World")
  }

  const updateCiudadRequest = async () => {
    setIsLoading(true)
    const body =  {
      "nombre_ciudad": nombreCiudad,
      "id_alianza_ciudad": idAlianzaCiudad,
      "usuario_alianza": usuarioAlianza,
      "password_alianza": passwordAlianza,
      "poliza_alianza_1": polizaAlianza1,
      "poliza_alianza_2": polizaAlianza2,
      "poliza_alianza_3": polizaAlianza3,
    }
    let url = ""
    let method = ""
    try{
      let result = await TemporalService.sessionRequest({
        method: "put",
        url: `ciudad/update/${idCiudad}`,
        headers: null,
        body: body,
        param: null,
      })
      console.log("Respuesta de la de actualización de ciudad -->",result.data)
      handleEmmit("Usuario registrado exitosamente", "SUCCESS")
      handleClose()
      // setIssuesData(result.data)
    }catch(e){
      // setEmmitMessage("Error al obtener los roles")
      console.log("Error de consultar los roles --> ", e.response)
      if(e.response.data){
        let mensaje = e.response.data.mensaje + " \n "
        if(e.response.data.result){
          const objErrores = e.response.data.result
          for(var key of Object.keys(objErrores)){
            mensaje += " " + objErrores[key][0] + " \n "
          }
          // deberiaSerUnArray.forEach((element, key) => {
          //   mensaje += key + " " + element[0] + "\n"
          // });
        }
        handleEmmit(mensaje, "ERROR")
      }else{
        handleEmmit("Error al consultar los roles", "ERROR")  
      }
    }finally{
      setIsLoading(false)
    }
  }
  const storeUserRequest = async () => {
    setIsLoading(true)
    const body =  {
      "nombre": nombre,
      "apellido_paterno": paterno,
      "apellido_materno": materno,
      "documento_identidad": documentoIdentidad,
      "extension": extension,
      "fecha_nacimiento": fechaNacimiento,
      "nro_celular": celular,
      "usuario": usuario,
      "tipo": rol
    }
    let url = ""
    let method = ""
    if(!flagEdicionCreacion){
      url = `user/edit/admin/${idUsuario}`
      method = "patch"
    }else{
      url = "user/create/admin/"
      method = "post"
    }
    try{
      let result = await TemporalService.sessionRequest({
        method: method,
        url: url,
        headers: null,
        body: body,
        param: null,
      })
      console.log("Respuesta de la Consulta Registro Usuario -->",result.data)
      handleClose()
      handleEmmit("Usuario registrado exitosamente", "SUCCESS")
      // setIssuesData(result.data)
    }catch(e){
      // setEmmitMessage("Error al obtener los roles")
      console.log("Error de consultar los roles --> ", e.response)
      if(e.response.data){
        let mensaje = e.response.data.mensaje + " \n "
        if(e.response.data.result){
          const objErrores = e.response.data.result
          for(var key of Object.keys(objErrores)){
            mensaje += " " + objErrores[key][0] + " \n "
          }
          // deberiaSerUnArray.forEach((element, key) => {
          //   mensaje += key + " " + element[0] + "\n"
          // });
        }
        handleEmmit(mensaje, "ERROR")
      }else{
        handleEmmit("Error al consultar los roles", "ERROR")  
      }

    }finally{
      setIsLoading(false)
    }
  }

  const rolRequest = async() => {
    setIsLoading(true)
    setRoles([])
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "user/get/rol",
        headers: null,
        body: null,
        param: null,
      })
      console.log("Respuesta de la Consulta de roles -->",result.data)
      // setIssuesData(result.data)
      setRoles(result.data)
    }catch(e){
      // setEmmitMessage("Error al obtener los roles")
      console.log("Error de consultar los roles --> ", e)
    }finally{
      setIsLoading(false)
    }
  }

  const setInitialParameters = async () => {
    setNombreCiudad(ciudadData?.nombre_ciudad)
    setIdAlianzaCiudad(ciudadData?.id_alianza_ciudad)
    setUsuarioAlianza(ciudadData?.usuario_alianza)
    setPasswordAlianza(ciudadData?.password_alianza)
    setPolizaAlianza1(ciudadData?.poliza_alianza_1)
    setPolizaAlianza2(ciudadData?.poliza_alianza_2)
    setPolizaAlianza3(ciudadData?.poliza_alianza_3)
    setIdCiudad(ciudadData?.id)
  }
  useEffect(() => {
    setInitialParameters()
  }, [ciudadData])

  useEffect(() => {
  }, [])
  
  return (
    <div>
      <Dialog
        open={statusModal}
        onClose={handleClose}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edición de Ciudad</DialogTitle>
        <DialogContent dividers={true}>
          
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="nombre_ciudad" 
                    label="Nombre:" 
                    value = {nombreCiudad}
                    variant="outlined" 
                    onChange={(event) => {
                      setNombreCiudad(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="id_alianza_ciudad" 
                    label="Identificador de Alianza:" 
                    value = {idAlianzaCiudad}
                    variant="outlined" 
                    onChange={(event) => {
                      setIdAlianzaCiudad(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="usuario_alianza" 
                    label="Usuario Alianza:" 
                    value = {usuarioAlianza}
                    variant="outlined" 
                    onChange={(event) => {
                      setUsuarioAlianza(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="password_alianza" 
                    label="Contraseña de Alianza:" 
                    value = {passwordAlianza}
                    variant="outlined" 
                    type="password"
                    onChange={(event) => {
                      setPasswordAlianza(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="poliza_alianza_1" 
                    label="Póliza 1:" 
                    value = {polizaAlianza1}
                    variant="outlined" 
                    onChange={(event) => {
                      setPolizaAlianza1(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="poliza_alianza_2" 
                    label="Póliza 1:" 
                    value = {polizaAlianza2}
                    variant="outlined" 
                    onChange={(event) => {
                      setPolizaAlianza2(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="poliza_alianza_3" 
                    label="Póliza 1:" 
                    value = {polizaAlianza3}
                    variant="outlined" 
                    onChange={(event) => {
                      setPolizaAlianza3(event.target.value)
                    }}
                  />
                </Grid>                
              </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled = {isLoading} onClick={handleClose}>Cancelar</Button>
          <Button disabled = {isLoading} onClick={handleSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </div>
    
  )
}
export default EditarCiudadModal