const colors = [
  "#e52121",
  "#e58321",
  "#e5e521",
  "#83e521",
  "#21e583",
  "#1d2766",
  "#5c1d66",
  "#661d4c",
  "#66371d",
]

const getColors = () => {
  return colors
}

export default getColors