import { Grid, Typography, Button, TextField, } from "@mui/material"
import { useEffect, useState } from "react";
import { Data } from "../../components/common/dataTemporal";
import { Data2 } from "../../components/common/dataTemporalAprobados";
import { Data3 } from "../../components/common/dataTemporalPie";
import BarChart from "../../components/chart/bar";
import PieChart from "../../components/chart/pie";
import LineChart from "../../components/chart/line";
// import PieChart from "../components/PieChart";
// import "./styles.css";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { TemporalService } from "../../components/services/temporalService";
import PolizaBarChartData from "./data/parsePolizaBarChartData"
import PolizaPieChartData from "./data/parsePolizaPieChartData"

Chart.register(CategoryScale);

const Dashboard = () => {
  const [polizaBarChartData, setPolizaBarChartData] = useState({labels: [], datasets: []})
  const [legendsBarChart, setLegendsBarChart] = useState([])
  const [barData, setBarData ] = useState(Data2)
  const [pieData, setPieData] = useState({labels: [], datasets: []})
  const [loading, setLoading ] = useState(false)
  
  const [chartData, setChartData] = useState({
    labels: Data.map((data) => data.year),

    datasets: [
      {
        label: "Cant. de descargas: ",
        data: Data.map((data) => data.userGain),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#f0331a",
          "#f3ba2f",
          "#2a71d0"
        ],
        borderColor: "black",
        borderWidth: 2
      }
    ]
  });

  const getPolizaBarChartData = async () => {
    setLoading(true)
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "poliza/chart/state",
        headers: null,
        body: null,
        param: null,
      })
      result = PolizaBarChartData(result.data.data)
      setPolizaBarChartData(result)
    }catch(e){
      console.log("Error al obtener lo datos para la gráfica de barras de la póliza")
      console.log(e)
    }finally{
      setLoading(false)
    }
  }

  const getPolizaPieChartData = async () => {
    setLoading(true)
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "poliza/pie/poliza/departamento",
        headers: null,
        body: null,
        param: null,
      })
      result = PolizaPieChartData(result.data.data)
      setPieData(result)
    }catch(e){
      console.log("Error al obtener lo datos para la gráfica de barras de la póliza")
      console.log(e)
    }finally{
      setLoading(false)
    }
  }


  useEffect(() => {

  }, [polizaBarChartData])

  useEffect(() => {
    getPolizaBarChartData()
    getPolizaPieChartData()
  }, [])
  

  return (
    <Grid container direction="row">
      {/* <BarChart chartData={chartData} /> */}
      {/* <Grid item xs={6}>
      <LineChart chartData={chartData} title={"Cantidad de Descargas"} subTitle={"Descargas de la aplicación a lo largo del tiempo"}  />
      </Grid> */}
      <Grid item xs={6}>
        <BarChart chartData={polizaBarChartData} 
          title={"Solicitudes de Póliza por Mes"} 
          subTitle={"Solicitud de póliza de los últimos 4 meses"} />
      </Grid>
      {/* <Grid item xs={3}></Grid> */}
      <Grid item xs={6}>
        <PieChart chartData={pieData} 
          title={"Solicitudes de Póliza por Departamento"} 
          subTitle={"Solicitud de póliza por departamento de los últimos 3 meses"} 
        />
      </Grid>
      {/* <Grid item xs={3}></Grid> */}
      {/* <PieChart chartData={chartData} /> */}
    </Grid>
  );
}
export default Dashboard