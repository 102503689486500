import { Grid, Typography, Button, TextField, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AlarmIcon from '@mui/icons-material/Alarm';
import IconButton from '@mui/material/IconButton';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TemporalService } from "../../../components/services/temporalService";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DetalleClienteModal = ({
  statusModal,
  clientParam,
  handleClose,
}) => {
  // console.log("STATUSMODAL", statusModal)
  // console.log("USERDATA", clientParam)
  const [clientData, setClientData] = useState()


  const handleActionUser = async (user) => {
    console.log(user)
    alert("Hello World")
  }

  const userDetailRequest = async () => {
    console.log("Obteniendo datos desde la parte de atras")
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: `datos_personales/complete/information/${clientParam.usuario_id}`,
        headers: null,
        body: null,
        param: null,
      })
      console.log("RESULTADO DETALLE CLIENTE:::",result.data)
      setClientData(result.data)
    }catch(e){

    }finally{

    }
  }

  useEffect(() => {
    userDetailRequest()
  }, [clientParam])
  
  return (
    <div>
      <Dialog
        open={statusModal}
        onClose={handleClose}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Datos del Cliente</DialogTitle>
        <DialogContent dividers={true}>
          
            <Grid container direction="column"  >
              <Grid container direction="row">
                <Grid item sx={2} md={2}>
                  <Typography>
                    Nombre:
                  </Typography>
                </Grid>
                <Grid item sx={10} md={10}>
                  <Typography>
                    <strong>{`
                      ${clientData?.nombre} 
                      ${clientData?.apellido_paterno} 
                      ${clientData?.apellido_materno}`
                      }</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item sx={2} md={2}>
                  <Typography>
                    Documento de Identidad: 
                  </Typography>  
                </Grid>
                <Grid item sx={2} md={2}>
                  <strong>{clientData?.tipo_documento}</strong> 
                </Grid>
                <Grid item sx={2} md={2}>
                  Nro. de Documento:
                </Grid>
                <Grid item sx={2} md={2}>
                  <strong>{clientData?.numero_documento}</strong> 
                </Grid>
                <Grid item sx={2} md={2}>
                  Extension:
                </Grid>
                <Grid item sx={2} md={2}>
                  <strong>{clientData?.extension}</strong>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item sx={3} md={3}>
                  <Typography>
                    Fecha de Nacimiento
                  </Typography>
                </Grid>
                <Grid item sx={3} md={3}>
                  <strong>{clientData?.fecha_nacimiento}</strong>
                </Grid>
                <Grid item sx={3} md={3}>
                  <Typography>
                    Nacionalidad:
                  </Typography>
                </Grid>
                <Grid item sx={3} md={3}>
                  <strong>{clientData?.nacionalidad}</strong>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item sx={3} md={3}>
                  <Typography>
                    Nro. de Celular: 
                  </Typography>
                </Grid>
                <Grid item sx={3} md={3}>
                  <strong>{clientData?.nro_celular}</strong>
                </Grid>
                <Grid item sx={3} md={3}>
                  <Typography>
                    Dirección: 
                  </Typography>
                </Grid>
                <Grid item sx={3} md={3}>
                  <strong>{clientData?.direccion_domicilio}</strong>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item sx={3} md={3}>
                  <Typography>
                    Actividad: 
                  </Typography>
                </Grid>
                <Grid item sx={3} md={3}>
                  <strong>{clientData?.actividad}</strong>
                </Grid>
                <Grid item sx={3} md={3}>
                  <Typography>
                    Estado: 
                  </Typography>
                </Grid>
                <Grid item sx={3} md={3}>
                  <strong>{clientData?.revision_estado}</strong>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item sx={6} md={6}>
                <Grid container direction="column">
                  <Grid item >
                    <Typography>
                      Imagen de CI (frontal):
                    </Typography>
                  </Grid>
                  <Grid item >
                    <img
                      src={`${clientData?.ci_frontal}`}
                      alt="CI del Usuario"
                      width="120px"
                    />
                  </Grid>
                </Grid>
                </Grid>
                <Grid item sx={6} md={6}>
                <Grid container direction="column">
                  <Grid item >
                    <Typography>
                      Imagen de CI (Posterior):
                    </Typography>
                  </Grid>
                  <Grid item >
                    <img
                      src={`${clientData?.ci_trasero}`}
                      alt="CI del Usuario"
                      width="120px"
                    />
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column">
                
              </Grid>
              <Grid container direction="column">
                <Typography>
                  Imagen de Perfil:
                </Typography>
                <div style={{ borderRadius : "20xp", }}>
                  <img
                    src={`${clientData?.imagen}`}
                    alt="Imagen de Usuario"
                    width="120px"
                  />
                </div>
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default DetalleClienteModal