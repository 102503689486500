import { Grid, Typography, Button, TextField, } from "@mui/material"
import React, { useEffect, useState } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AlarmIcon from '@mui/icons-material/Alarm';
import IconButton from '@mui/material/IconButton';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TemporalService } from "../../../components/services/temporalService";
import Divider from '@mui/material/Divider';
// import { MotivoRechazo }
import MotivoRechazoPoliza from './motivoRechazoPoliza'
import SlideImagenes from "./slideImagenes";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalDetallePoliza = ({
  statusModal,
  polizaParam,
  handleClose,
  handleRechazo
}) => {
  // console.log("STATUSMODAL::::::", statusModal)
  // console.log("POLIZADATA::::::::", polizaParam)
  const [polizaData, setPolizaData] = useState()
  const [loading, setLoading] = useState(false)
  const [optionSelected, setOptionSelected] = useState(false)
  const [observationRejected , setObservationRejected] = useState()
  const [validationObservation , setValidationObservation] = useState(false)
  const [imagesPath, setImagesPath] = useState([])
  const [nameImagesPath, setNameImagesPath] = useState([])


  const handleActionUser = async (user) => {
    console.log(user)
    alert("Hello World")
  }
  const handleCancelarRechazo = () => {
    setOptionSelected(false)
  }

  const handleRechazo2 = (poliza_data)=>{
    console.log("AAAAAA...... no se si entra aca.....")
    setOptionSelected(true)
    // handleRechazo(poliza_data)
  }

  const handelSaveRechazo = (poliza_data) => {
    console.log('Verificando la informacion', observationRejected)
    if(observationRejected.length > 0){
      setValidationObservation(false)
      handleRechazo(poliza_data, observationRejected)
    }else{
      setValidationObservation(true)
    }
  }

  const handleActionEmmit = async () => {
    emmitRequest(polizaData)
  }

  const emmitRequest = async (data) => {
    setLoading(true)
    try{
      const response = await TemporalService.sessionRequest({
        method: "get",
        url: `alianza/emitir/?id_poliza=${data.id}`,
        headers: null,
        body: null,
        param: null,
      })
      if(response.data.detail.lstErrors.length > 0){
        let error = ""
        response.data.detail.lstErrors.forEach(element => {
          error += " " + element.sMessaged
        });
        alert(error)
      }else{
        // polizaDetailRequest()
      }
      
    }catch(e){
      alert("Error Inesperado")
      console.log(e)
    }finally{
      setLoading(false)
    }
  }

  const polizaDetailRequest = async () => {
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: `poliza/detail/${polizaParam.id}`,
        headers: null,
        body: null,
        param: null,
      })
      console.log(result.data)
      setPolizaData(result.data)
      setImagesPath([
        result.data?.inspeccion.foto_frontal, 
        result.data?.inspeccion.foto_trasero, 
        result.data?.inspeccion.lateral_izq,
        result.data?.inspeccion.lateral_der,
        result.data?.inspeccion.tablero,
        result.data?.inspeccion.damage,
        result.data?.automovil.ruat
      ])
      setNameImagesPath([
        'Frontal',
        'Posterior',
        'Lateral Izquierda',
        'Lateral Derecha',
        'Tablero del Automóvil',
        'Algún daño',
        'RUAT',
      ])
      
    }catch(e){

    }finally{

    }
  }

  useEffect(() => {
    setOptionSelected(false)
    setObservationRejected("")
    setValidationObservation(false)
    polizaDetailRequest()
  }, [polizaParam])
  
  return (
    <>
      <div>
        <Dialog
          open={statusModal}
          onClose={handleClose}
          scroll={'paper'}
          fullWidth={true}
          maxWidth={'md'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Datos de la Póliza</DialogTitle>
          
          <DialogContent dividers={true}>
            <Typography variant="primary">
              <strong>
                Datos del Cliente
              </strong>
            </Typography>
            <Divider />
              <Grid container direction="column"  >
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography variant="secondary">
                      Nombre: 
                    </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <strong>{`
                      ${polizaData?.datos_personales.nombre} 
                      ${polizaData?.datos_personales.apellido_paterno} 
                      ${polizaData?.datos_personales.apellido_materno}`
                      }</strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography >
                      Tipo de Documento: 
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <strong>{`${polizaData?.datos_personales.tipo_documento} `}
                    </strong>
                  </Grid>
                  <Grid item md={2}>
                    <Typography >
                      Documento de Identidad: 
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <strong>{`${polizaData?.datos_personales.numero_documento}`}</strong>
                  </Grid>
                  <Grid item md={2}>
                  <Typography >
                      Expedido: 
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <strong>{`${polizaData?.datos_personales.extension} `}</strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Dirección:
                    </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <strong>{`${polizaData?.datos_personales.direccion_domicilio} `}</strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Nacionalidad:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.datos_personales.nacionalidad} `}
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Teléfono:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.datos_personales.nro_celular} `}
                    </strong>
                  </Grid>
                </Grid>
                {!optionSelected ? 
                <><Grid container direction="row">
                  <Grid item>
                    <Typography variante='primary'>
                      <strong>
                        Inspección
                      </strong>
                    </Typography>  
                  </Grid>
                </Grid>
                <Divider />
                <SlideImagenes imagenesArray = {imagesPath} titlesArray={nameImagesPath}></SlideImagenes>
                <Grid container direction="row">
                  <Grid item>
                    <Typography variant='primary'>
                      <strong>
                        Datos del Automóvil
                      </strong>
                    </Typography>  
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Ciudad:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.ciudad.nombre_ciudad} `}
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Tipo de Uso:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.uso.nombre_uso} `}
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Año:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.year.year} `}
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Modelo:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.modelo.nombre_modelo} `}
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Marca:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.modelo.nombre_marca} `}
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Placa:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.placa} `}
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Chasis:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.chasis} `}
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Color:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.color} `}
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Motor:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.motor} `}
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Nro. de Asientos:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.asientos_nro} `}
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Cilindrada:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.cilindrada} `}
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Peso:
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {`${polizaData?.automovil.toneladas} `} Toneladas
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item>
                    <Typography variant="primary">
                      <strong>
                        Datos de la Póliza
                      </strong>
                    </Typography>  
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction="row">
                  
                  <Grid item md={3}>
                    <Typography>
                      Monto Asegurado:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                    {`${polizaData?.automovil.valor_asegurado} `} 
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Estado:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                    {`${polizaData?.estado_revision} `} 
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Inicio de la Cobertura:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                    {`${polizaData?.vigencia_inicio} `} 
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Finalización de la cobertura:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                    {`${polizaData?.vigencia_final} `} 
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Cantidad de Dias de cobertura
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                    {`${polizaData?.nro_dias} `} 
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Nro. de Póliza:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    {
                      polizaData?.nro_poliza != null &&
                      <strong>
                        {`${polizaData?.nro_poliza} `} 
                      </strong>
                    }
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Prima Neta:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                    {`${polizaData?.prima_neta} `} 
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Monto de Pago:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                    {`${polizaData?.monto_pago} `} 
                    </strong>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item md={3}>
                    <Typography>
                      Slip:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      <a target="_blank" href={`${process.env.REACT_APP_BACK_END_URL}/media/formularios/${polizaData?.url_slip}`} download>Descargar</a>
                    </strong>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>
                      Certificado:
                    </Typography>  
                  </Grid>
                  <Grid item md={3}>
                    <strong>
                      {
                        polizaData?.url_certificado_descargado != null &&
                          <a target="_blank" href={`${process.env.REACT_APP_BACK_END_URL}/${polizaData?.url_certificado_descargado}`} download>Descargar</a>
                      }
                    </strong>
                  </Grid>
                </Grid></>
                :
                <>
                  <MotivoRechazoPoliza setObservation = {setObservationRejected} >
                  </MotivoRechazoPoliza>
                  {
                    validationObservation &&
                  <Grid item container>
                    <Grid item md={12}>
                      <Typography align="center" sx={{ color: 'red' }} >Debe seleccionar alguna opción.</Typography>
                    </Grid>
                  </Grid>
                  }
                </>
                }
              </Grid>
          </DialogContent>
          <DialogActions>
            {
              !optionSelected ? <>
                {
                  polizaData?.estado_revision == "PENDIENTE" && 
                  <Button onClick={() => {handleRechazo2(polizaData)}}>Rechazar</Button>
                }
                {
                  false && polizaData?.estado_revision == "APROBADO" && 
                  <Button onClick={handleActionEmmit}>Sincronizar Pago</Button>
                }
                {
                  polizaData?.estado_revision == "PENDIENTE" && 
                  <Button onClick={handleClose}>Aprobar</Button>
                }
              </>:<>
                {
                  polizaData?.estado_revision == "PENDIENTE" && 
                  <Button onClick={() => {handelSaveRechazo(polizaData)}}>Rechazar</Button>
                }
                <Button onClick={() => {handleCancelarRechazo()}}>Cancelar</Button>
              </>
            }
            
            <Button onClick={handleClose}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
export default ModalDetallePoliza