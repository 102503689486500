import { red } from '@mui/material/colors';
// import { createTheme } from '@mui/material/styles';
import { createTheme } from '@material-ui/core/styles';
export const darktheme = createTheme ({
  palette: {
    type: 'dark',
    primary: {
      main: '#1A2461',
    },
    secondary: {
      main: '#E52121',
    },
  },
  typography: {
    nota: {
      fontSize: 12,
      fontStyle: "italic"
    },
    columnTittle: {
      fontStyle: "bold"
    }
  },
  variants : {
    solid: {
      fontStyle: "bold"
    }
  },
  '@vars' :{
    radius:"20px"
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }
  // overrides:{
  //   MuiTypography:{
  //     subtitle1:{
  //       fontSize: '100000px',
  //       color: 'red',
  //     }
  //   }
  // },
  
})

export const ligththeme = createTheme ({
  palette: {
    type: 'light',
    primary: {
      main: '#1A2461',
    },
    secondary: {
      main: '#E52121',
    },
  },
})

export const colors = {
  ligth_mode: {
    principal_blue: "#1A2461",
    principal_red: "#E52121"
  },
  dark_mode: {
    principal_blue: "#1A2461",
    principal_red: "#E52121"
  }
}