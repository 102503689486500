import axios, {AxiosError} from "axios"

const basePath = "http://api.mibigbro.com:8000/api/"
// const basePath = "http://127.0.0.1:8000/api/"
export const correctStates = [200, 201, 202, 204]

class ServiceClass {
  async sessionRequest(method, url, headerParam,  body, param) {  
    const fullPath = basePath + url
    return axios({
      method: method,
      url: fullPath,
      headers: {
        'Access-Control-Allow-Origin': '181.188.186.158'
      },
      
      data: body,
      params: param,
      withCredentials: false,
      validateStatus: (status)=>{
        return correctStates.some((estate)=> estate === status )
      },
    })//timeout: 20000,
  }

  async sessionRequest2(url){
    const fullPath = basePath + url
    axios.get(fullPath, {
      headers: {
        'Accept': '*/*',
      },
    }).then(function(response){
      console.log("TODO BIENNMM")
      console.log(response)
    }).catch(function(error){
      console.log("ERRRRRRRRRRRRROOOOOO")
      console.log(error)
    })
  }

  isNetworkError(err) {
    return !!err.isAxiosError && !err.response
  }
}

export const Service = new ServiceClass()