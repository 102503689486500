import { Grid, Typography, Button, TextField, } from "@mui/material"
import React, { useEffect, useState } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AlarmIcon from '@mui/icons-material/Alarm';
import IconButton from '@mui/material/IconButton';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TemporalService } from "../../../components/services/temporalService";
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { razonesRechazo } from '../../../utils/rechazo'

const MotivoRechazoPoliza = ({
  // statusModal,
  // polizaParam,
  // handleClose,
  // handleRechazo
  setObservation
}) => {
  // console.log("STATUSMODAL::::::", statusModal)
  // console.log("POLIZADATA::::::::", polizaParam)
  const [polizaData, setPolizaData] = useState()
  const [loading, setLoading] = useState(false)
  const [observations, setObservations] = useState([])
  const polizaParam = null;


  const handleActionUser = async (user) => {
    console.log(user)
    alert("Hello World")
  }

  const handleActionEmmit = async () => {
    emmitRequest(polizaData)
  }

  const emmitRequest = async (data) => {
    setLoading(true)
    try{
      const response = await TemporalService.sessionRequest({
        method: "get",
        url: `alianza/emitir/?id_poliza=${data.id}`,
        headers: null,
        body: null,
        param: null,
      })
      if(response.data.detail.lstErrors.length > 0){
        let error = ""
        response.data.detail.lstErrors.forEach(element => {
          error += " " + element.sMessaged
        });
        alert(error)
      }else{
        // polizaDetailRequest()
      }
      
    }catch(e){
      alert("Error Inesperado")
      console.log(e)
    }finally{
      setLoading(false)
    }
  }

  const polizaDetailRequest = async () => {
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: `poliza/detail/${polizaParam.id}`,
        headers: null,
        body: null,
        param: null,
      })
      setPolizaData(result.data)        
    }catch(e){

    }finally{

    }
  }

  const handleChange = (event, razon) => {
    let temporalObservations = observations
    const observation = temporalObservations.indexOf(razon)
    const checked = event.target.checked
    if(observation > -1){
      if(!checked){
        temporalObservations.splice(observation, 1)
      }
    }else{
      if(checked){
        temporalObservations.push(razon)
      }
    }
    setObservations(temporalObservations)
    const joinedObservations = temporalObservations.join(';');
    setObservation(joinedObservations)
  }

  useEffect(() => {
    console.log('asdasd')
    // polizaDetailRequest()
  }, [])
  
  return (
    <div>
      <br />
      <Typography variant="primary">
        <strong>
          Motivo del Rechazo
        </strong>
      </Typography>
      <Divider />
      <Grid container direction="column"  >
        <Grid item md={3}>
          <FormGroup>
            {
              razonesRechazo.map((razon) => {
                return <FormControlLabel onChange={(event) => {handleChange(event, razon)}} control={<Checkbox />} label={razon} />
              })
            }
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  )
}
export default MotivoRechazoPoliza