import { Grid, Typography, TextField, Button, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VerifiedIcon from '@mui/icons-material/Verified';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import AgregarEditarPersonalModal from "./ui/agregarEditarPersonalModal";
import DetalleClienteModal from "./ui/detalleClienteModal"
import { TemporalService } from "../../components/services/temporalService";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Clientes = () => {
  const [clientData, setClientData] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusModalPersonal, setModalPersonal] = useState(false)
  const [detailUserModal, setDetailUserModal] = useState(false)
  const [userDetail, setUserDetail] = useState()
  const [loading, setLoading] =useState(false)
  console.log(statusModalPersonal)
  const [userDataEdit, setUserDataEdit] = useState()
  const [query, setQuery] = useState("")
  const [showEmmitError, setShowEmmitError] = useState(false)
  const [showEmmitSuccess, setShowEmmitSuccess] = useState(false)
  const [emmitMessage, setEmmitMessage] = useState("")
  const [total, setTotal] = useState(0)
  const [preQuery, setPreQuery] = useState(0)

  const handleClose = () => {
    setShowEmmitError(false)
    setShowEmmitSuccess(false)
    setEmmitMessage("")
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleCloseModal = async () => {
    setDetailUserModal(false)
  }
  const handleFindByQuery = async () => {
    setPage(0)
    getClientsRequest()
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClientsRequest = async () => {
    setLoading(true)
    setClientData([])
    const param = {
      query: query,
      per_page: rowsPerPage,
      page: page,
    }
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "datos_personales/list/filtered?tipo=CLIENTE",
        headers: null,
        body: null,
        param: param,
      })
      console.log("Respuesta de la petición")
      console.log(result.data)
      setTotal(result.data.count)
      setClientData(result.data.results)
    }catch(e){

    }finally{
      setLoading(false)
    }
  }

  const activateUserRequest = async (clientId) => {
    setLoading(true)
    setClientData([])
    try{
      const result = await TemporalService.sessionRequest({
        method: "get",
        url: `alianza/create_client/?id_user=${clientId}`,
        headers: null,
        body: null,
        param: null,
      })
      console.log(result)
      setEmmitMessage(result.data.message)
      if(result.data.status == 1){
        setShowEmmitSuccess(true)
        setShowEmmitError(false)
      }else{
        setShowEmmitError(true)
        setShowEmmitSuccess(false)
      }
      getClientsRequest()
    }catch(e){
      setEmmitMessage("Error al consumir el servicio de alianza, verifique su conexión de internet")
      setShowEmmitSuccess(false)
      setShowEmmitError(true)
    }finally{
      setLoading(false)
    }
  }

  const columns = [
    { id: 'full_name', label: 'Nombre Completo' },//minWidth: 170
    { id: 'documento_identidad', label: 'Documento de Identidad' },
    { id: 'nro_celular', label: 'Celular' },
    { id: 'revision_estado', label: 'Estado' },
    { id: 'actions', label: 'Acciones' },
  ]

  const handleActivateUser = async(userId) => {
    activateUserRequest(userId)
  }

  const handleDetailUserViewer = async(userData) => {
    console.log("User DAata::",userData)
    setUserDetail(userData)
    await timeOut(1000)
    setDetailUserModal(true)
  }

  function timeOut(delay){
    return new Promise(res => setTimeout(res, delay))
  }

  useEffect(() => {
    getClientsRequest()
  }, [page, rowsPerPage, ])
  

  useEffect(() => {
    getClientsRequest()
  }, [])
  
  return (
    <Grid container direction="column" rowSpacing={3}>
      {/* <Grid item xs={12}>
        <Typography align="left">Usuario:</Typography>
      </Grid> */}
      { 
        <Snackbar open={showEmmitError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar open={showEmmitSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      <Box
        sx={{
          width: 40,
          height: 40,
        }}
      />
      <Typography component={'span'} variant="h3" align="left">
        Listado de Clientes
      </Typography>
      <Grid container direction="row" rowSpacing={3} alignItems={'center'}>
      <Grid item >
          <TextField 
            id="emision" 
            label="Criterio:" 
            variant="outlined" 
            onChange={(event)=> {
              setQuery(event.target.value)
            }}
          />
      </Grid>
      <Grid item xs={3} md={3} >
          <Button variant="contained" onClick={handleFindByQuery}>Buscar</Button>
      </Grid>
      </Grid>

      <Grid item xs={12} >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer >
            {
              loading && <LinearProgress />
            }
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography 
                        component={'span'} 
                        variant="columnTittle"
                        key={`texto-${column.id}`}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {clientData
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={`table-${data.usuario_id}`}>
                        <TableCell key={`${data.usuario_id}-full-name`}>
                          {`${data.nombre} ${data.apellido_paterno} ${data.apellido_materno}`}
                        </TableCell>
                        <TableCell key={`${data.usuario_id}-documento-identidad`}>
                          {data.numero_documento}
                        </TableCell>
                        <TableCell key={`${data.usuario_id}-nro-celular`}>
                          {data.nro_celular}
                        </TableCell>
                        <TableCell key={`${data.usuario_id}-revision-estado`}>
                          {data.revision_estado}
                        </TableCell>
                        <TableCell key={`${data.id}-acciones`}>
                          <Stack spacing={2} direction="column">
                            {
                              data.revision_estado == 'NUEVO' &&
                              <Button variant="contained" key={`validar-${data.id}`} onClick={()=> {handleActivateUser(data.usuario_id)}}>
                                Validar
                              </Button>
                            }
                            <Button variant="contained" key={`detalle-${data.id}`} onClick={()=> {handleDetailUserViewer(data)}}>
                              Ver
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      <DetalleClienteModal 
        statusModal = {detailUserModal} 
        clientParam = {userDetail}
        handleClose = {handleCloseModal}
      >
      </DetalleClienteModal>
    </Grid>
  )
}
export default Clientes