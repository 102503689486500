import React, { useEffect, useState } from "react"
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import {colors, darktheme} from '../common/themes'
import { ThemeProvider } from "@mui/material"
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';

import favicon from '../../assets/images/web-page/favicon.png'
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie'
import Cookies from 'universal-cookie';


const drawerWidth = 240;



const Header = ({
  title,
  externalAction,
}) => {

  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [nombreUsuario, setNombreUsuario] = useState("NP")
  const [cookie, removeCookie] = useCookies()
  const navigate = useNavigate();
  

  

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  };
  const handleCloseSession = () => {
    removeCookie("user")
    removeCookie("token")
    removeCookie("tipo")
  }

  const handleOptionUserMenu = (setting) => {
  console.log("Ejecutando El manejador de opciones")
  if(setting.action !=  null)
    setting.action()
  navigate(setting.url)  
}

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  };


  const handleCloseUserMenu = (setting) => {
    // console.log("Ejecutando el cerrado del menu")
    // handleCloseSession()
    // handleOptionUserMenu(setting)
    setAnchorElUser(null)
  };
  
  const handleDrawerToggle = () => {
    externalAction()
  };

  const settings = [
    {
      "id" : 1,
      "name" : 'Cambiar Contraseña',
      "action" : null,
      "url" : "/cambio-contrasenia"
      // "url" : "/"
    },
    {
      "id" : 2,
      "name" : 'Cerrar Sesión', 
      "action" : handleCloseSession,
      "url" : "/"
    },
  ]

  useEffect(() => {
    if(cookie.usuario_nombre != undefined){
      console.log("Nombre usuario: ",cookie.usuario_nombre)
      setNombreUsuario(cookie.usuario_nombre)
    }
  })
  
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100%)` },
        ml: { sm: `${drawerWidth}px` },
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <img src={favicon} alt="MiBigBro" width="80px" height="80px" />
        <Typography variant="h6" component="div">
          MiBigBro
        </Typography>
        <Grid container style={{ width: "95%", justifyContent:'flex-end' }}>
        { title != "EXTERNO" &&
          <Box sx={{ flexGrow: 0 }} style={{ paddingRight: '0px', justifyContent:'end' }}>
            <Tooltip title="Opciones" style={{ paddingRight: '0px' }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color:"white" }}>
                <Typography variant="h6" component="div">
                  {nombreUsuario}
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.id} onClick={() => {handleOptionUserMenu(setting)}}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
export default Header