import { Grid, Typography, Button, TextField  } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { TemporalService } from "../../components/services/temporalService";
import { useCookies } from 'react-cookie'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = ({}) => {
  const [user,setUser] = useState()
  const [password,setPassword] = useState()
  const [loading,setLoading] = useState()
  const [cookie, setCookie] = useCookies()
  const [showEmmitError, setShowEmmitError] = useState(false)
  const [showEmmitSuccess, setShowEmmitSuccess] = useState(false)
  const [emmitMessage, setEmmitMessage] = useState('')

  const handleClose = () => {
    setShowEmmitError(false)
    setShowEmmitSuccess(false)
  }

  const handleClick = async (path) => {
    const result = await loginRequest()
    if(result){
      navigate(path)
    }
    
  }
  const handleUser = (e) => {
    setUser(e.target.value)
  }
  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const loginRequest = async () => {
    setLoading(true)
    const body = {
      email: user,
      name: user,
      password: password,
    }
    try{
      let result = await TemporalService.externalRequest({
        method: "post",
        url: "user/token/",
        headers: null,
        body: body,
        param: null,
      })
      if(result.status == 200){
        setCookie('user', result.data.email)
        setCookie('token', result.data.token)
        setCookie('tipo', result.data.type)
        setCookie('iniciado', result.data.iniciado)
        setCookie('menus', result.data.menus)
        setCookie('usuario_nombre', result.data.usario_nombre)
        console.log(result.data)
      }
      return true
      
    }catch(e){
      console.log(e)//Unable to authenticate with provided credentials
      if(e.response.data.non_field_errors[0] == "Unable to authenticate with provided credentials"){
        setEmmitMessage("Usuario o contraseña incorrectos")
      }else{
        setEmmitMessage(e.response.data.non_field_errors[0])
      }
      setShowEmmitError(true)
    }finally{
      setLoading(false)
    }
    return false
  }

  const navigate = useNavigate();

  return (
    <Grid container direction="column" rowSpacing={3}>
      { 
        <Snackbar open={showEmmitError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar open={showEmmitSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      {/* <Grid item xs={12}>
        <Typography align="left">Usuario:</Typography>
      </Grid> */}
      <Box
        sx={{
          width: 25,
          height: 20,
        }}
      />
      <Grid item xs={12}>
        <TextField 
          id="usuario" 
          label="Usuario:" 
          variant="outlined"
          onChange={handleUser}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Typography align="left">Contraseña:</Typography>
      </Grid> */}
      <Grid item xs={12}>
        <TextField 
          id="password" 
          label="Contraseña:" 
          variant="outlined" 
          type="password"
          onChange={handlePassword}
        />
      </Grid>
      {/* <Typography align="center" variant="nota">¿Olvido su contraseña?</Typography> */}
      <Grid item xs={12}>
        <Button variant="contained" onClick={()=> {handleClick('/dashboard')}}>Iniciar Sesión</Button>
      </Grid>
    </Grid>
  )
}
export default Login