import React, { useEffect, useState } from "react"
import Layout from './components/layout/layout';
import './App.css';
import {
  BrowserRouter as Router, 
  Routes,
  Route,
} from "react-router-dom";
import ExternalLayout from './components/layout/externalLayout';
import Login from './modules/auth/login'
import Personal from './modules/usuarios/personal';
import Clientes from './modules/usuarios/clientes';

import Emitir from './modules/poliza/emitir'
import Cobros from './modules/poliza/cobros'
import Ciudad from './modules/administrador/ciudad'
import Tasa from './modules/administrador/tasa'
import Compania from './modules/administrador/compania'
import CambioContrasenia from './modules/user/cambioContrasenia'
import Dashboard from './modules/dashboard/dashboard'

function App() {

  const [show, setShow] = useState(false);


  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<ExternalLayout><Login /></ExternalLayout>} />
          <Route path="/dashboard" element={<Layout><Dashboard></Dashboard></Layout>} />
          <Route path="/usuarios/personal" element={<Layout><Personal></Personal></Layout>} />
          <Route path="/poliza/emitir" element={<Layout><Emitir></Emitir></Layout>} />
          <Route path="/poliza/cobros" element={<Layout><Cobros></Cobros></Layout>} />
          <Route path="/usuarios/clientes" element={<Layout><Clientes></Clientes></Layout>} />
          <Route path="/ciudad/lista" element={<Layout><Ciudad></Ciudad></Layout>} />
          <Route path="/tasa/lista" element={<Layout><Tasa></Tasa></Layout>} />
          <Route path="/compania/lista" element={<Layout><Compania></Compania></Layout>} />
          <Route path="/cambio-contrasenia" element={<Layout><CambioContrasenia></CambioContrasenia></Layout>} />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
