const meses = {
  0: "Enero",
  1: "Febrero",
  2: "Marzo",
  3: "Abril",
  4: "Mayo",
  5: "Junio",
  6: "Julio",
  7: "Agosto",
  8: "Septiembre",
  9: "Octubre",
  10: "Noviembre",
  11: "Diciembre",
}

export const Data2 = {
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July"
  ],
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      //stack: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [65, 59, 80, 81, 56, 55, 40]
    },

    {
      label: "My second dataset",
      backgroundColor: "rgba(155,231,91,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      //stack: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [45, 79, 50, 41, 16, 85, 20]
    },

    {
      label: "My third dataset",
      backgroundColor: "rgba(155,231,91,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      //stack: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [45, 79, 50, 41, 16, 85, 20]
    }
  ]
}