import { Grid } from "@mui/material"
import React from "react";
import { Bar } from "react-chartjs-2";

function BarChart({ chartData, subTitle, title }) {
  return (
    <Grid container direction={"column"} rowSpacing={2} className="chart-container">
      <Grid item>
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      <Bar
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: subTitle
            },
            legend: {
              display: true
            }
          }
        }}
      />
      </Grid>
    </Grid>
  );
}

export default BarChart;