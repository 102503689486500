import { Grid, Typography, TextField, Button, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VerifiedIcon from '@mui/icons-material/Verified';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { TemporalService } from "../../components/services/temporalService";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { FormHelperText } from '@mui/material';
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CambioContrasenia = () => {
  const navigate = useNavigate();
  const [clientData, setClientData] = useState([])

  const [statusModalPersonal, setModalPersonal] = useState(false)
  const [detailUserModal, setDetailUserModal] = useState(false)
  const [userDetail, setUserDetail] = useState()
  const [loading, setLoading] =useState(false)
  console.log(statusModalPersonal)
  const [userDataEdit, setUserDataEdit] = useState()
  const [query, setQuery] = useState("")
  const [showEmmitError, setShowEmmitError] = useState(false)
  const [showEmmitSuccess, setShowEmmitSuccess] = useState(false)
  const [emmitMessage, setEmmitMessage] = useState("")
  const [total, setTotal] = useState(0)
  const [preQuery, setPreQuery] = useState(0)

  const [cookie, setCookie, removeCookie] = useCookies()

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [errorOldPassword, setErrorOldPassword] = useState({"state": false, "message" : ""})
  const [errorNewPassword, setErrorNewPassword] = useState({"state": false, "message" : ""})
  const [errorConfirmPassword, setErrorConfirmPassword] = useState({"state": false, "message" : ""})

  const handleClose = () => {
    setShowEmmitError(false)
    setShowEmmitSuccess(false)
    setEmmitMessage("")
  }

  const handleChandePassword = () =>{
    setPasswordRequest()
  }

  const resetValidators = () => {
    setErrorNewPassword({"state" : false, "message" : ""})
    setErrorConfirmPassword({"state" : false, "message" : ""})
    setErrorOldPassword({"state" : false, "message" : ""})
  }

  const validateParams = () => {
    let result = true
    resetValidators()
    if(newPassword == ""){
      let error = errorNewPassword
      error.state = true
      error.message = "La nueva contraseña es requerida"
      setErrorNewPassword(error)
      result =  false
    }
    if(newPassword != confirmPassword){
      let error = errorConfirmPassword
      error.state = true
      error.message = "Las contraseñas no coinciden"
      setErrorConfirmPassword(error)
      result = false
    }
    if(oldPassword == "" || oldPassword == undefined){
      let error = errorOldPassword
      console.log("Error de la contraseña antigua")
      console.log(errorOldPassword)
      error.state = true
      error.message = "La actual contraseña es requerida"
      setErrorOldPassword(error)
      result = false
    }
    return result
  }

  const constructParams = () => {
    return {
      "old_password": oldPassword,
      "new_password": newPassword,
      "confirm_password": confirmPassword,
    }
  }

  const setPasswordRequest = async() => {
    setLoading(true)
    try{
      if(!validateParams()){
        throw("Error en el formulario, verifique porfavor")
      }
      const result = await TemporalService.sessionRequest({
        method: "post",
        url: `user/change-password/admin/`,
        headers: null,
        body: constructParams(),
        param: null,
      })
      console.log("Resultado de la periticion")
      console.log(result)
      setEmmitMessage(result.data.mensaje)
      if(result.data.status == 0){
        setEmmitMessage("La contraseña se ha modificado correctamente.")
        setCookie('iniciado', "true")
        setShowEmmitSuccess(true)
        setShowEmmitError(false)
        if(cookie.path){
          navigate(cookie.path)
          removeCookie("path")
        }else{
          navigate("/")
        }
      }else{
        setShowEmmitError(true)
        setShowEmmitSuccess(false)
      }
    }catch(e){
      console.log("Errro")
      console.log(e)
      let mensaje = "Error al consumir el servicio de alianza, verifique su conexión de internet"
      if(typeof(e) != "object"){
        mensaje = e
      }else if(e.response.data){
        mensaje = e.response.data.mensaje
        if(e.response.data.detalle){

        }
      }
      setEmmitMessage(mensaje)
      setShowEmmitSuccess(false)
      setShowEmmitError(true)
    }finally{
      setLoading(false)
    }
  }

  const handleDetailUserViewer = async(userData) => {
    console.log("User DAata::",userData)
    setUserDetail(userData)
    await timeOut(1000)
    setDetailUserModal(true)
  }

  function timeOut(delay){
    return new Promise(res => setTimeout(res, delay))
  }

  useEffect(() => {
  }, [])
  
  return (
    <Grid container direction="column" rowSpacing={3}>
      {/* <Grid item xs={12}>
        <Typography align="left">Usuario:</Typography>
      </Grid> */}
      { 
        <Snackbar open={showEmmitError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar open={showEmmitSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      <Box
        sx={{
          width: 40,
          height: 40,
        }}
      />
      <Typography component={'span'} variant="h3" align="left">
        Cambio de Contraseña
      </Typography>
      <Grid container direction="column" rowSpacing={3} alignItems={'center'}>
      <Grid item >
          <TextField 
            id="old_password" 
            label="Contraseña Actual:" 
            autoComplete='off'
            variant="outlined" 
            type="password"
            onChange={(event)=> {
              setOldPassword(event.target.value)
            }}
          />
          {
            errorOldPassword.state &&
            <FormHelperText id="helper-old-password" error={errorOldPassword.state}>{errorOldPassword.message}</FormHelperText>
          }
          
      </Grid>
      <Grid item >
          <TextField 
            id="new_password" 
            label="Nueva Contraseña:" 
            autoComplete='off'
            variant="outlined" 
            type="password"
            onChange={(event)=> {
              setNewPassword(event.target.value)
            }}
          />
          {
            errorNewPassword.state &&
            <FormHelperText id="helper-new-password" error={errorNewPassword.state}>{errorNewPassword.message}</FormHelperText>
          }
      </Grid>
      <Grid item >
          <TextField 
            id="confirm_password" 
            label="Confirme la Contraseña:" 
            autoComplete='off'
            type="password"
            variant="outlined" 
            onChange={(event)=> {
              setConfirmPassword(event.target.value)
            }}
          />
          {
            errorConfirmPassword.state &&
            <FormHelperText id="helper-confirm-password" error={errorConfirmPassword.state}>{errorConfirmPassword.message}</FormHelperText>
          }
      </Grid>

      <Grid item xs={3} md={3} >
          <Button variant="contained" onClick={handleChandePassword}>Confirmar</Button>
      </Grid>
      </Grid>
    </Grid>
  )
}
export default CambioContrasenia
