import { ConstructionOutlined } from '@mui/icons-material';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { TemporalService } from "./components/services/temporalService";

var firebaseConfig = {
  apiKey: "AIzaSyAQbGfCklCp916UNKgUdHRGTVa6WTyCTyk",
  authDomain: "mibigbro-355b6.firebaseapp.com",
  projectId: "mibigbro-355b6",
  storageBucket: "mibigbro-355b6.appspot.com",
  messagingSenderId: "340746981101",
  appId: "1:340746981101:web:cb7e53e27bb158801246e4",
  measurementId: "G-PW18V2Q67N"
};


export const fetchToken = async  (setTokenFound) => {
  // verifyNotification()
  let theToken = ""
  await getToken(messaging, {vapidKey: 'BMgZIpPNzsHxiTIct7d1JO5e7qNtGySIE7DdWFXqZb0mt9bi8R2x-blbsIbu6LFjYjAKMznZ-70vcMZP5HCkDfU'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      theToken = currentToken 
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
  return theToken
}

const requestSaveToken = async (token) =>{
  const body = {
    token: token,
  }
  try{
    let result = await TemporalService.sessionRequest({
      method: "post",
      url: "notification/web/register/",
      headers: null,
      body: body,
      param: null,
    })
    if(result.status == 200){
      console.log("Todo registrado correctamente")
      console.log(result)
    }
    return true
    
  }catch(e){
    console.log(e)//Unable to authenticate with provided credentials
  }finally{
  }
}
// TODO: Descomentar cuando el dominio ya cuente con el certificado
export const verifyNotification = async (setTokenFound) => {
  let result = ""
  if(!('Notification' in window) || !('serviceWorker' in navigator)) {
    console.log('Tu browser no soporta notificaciones')
    return ""
    // return 'Tu browser no soporta notificaciones'
  }
  result = Notification.permission
  if(Notification.permission === 'default') {
    console.log('Solicitando los permisos')
    result = await Notification.requestPermission()
    console.log(result)
    // return result
  }
  if (Notification.permission === 'blocked') {
    console.log('Bloqueaste las notificaciones')
    result = await Notification.requestPermission()
    console.log(result)
    // return 'Bloqueaste las notificaciones'
  }
  if(Notification.permission !== 'granted') {
    console.log('Solicitando 2')
    result = await Notification.requestPermission()
    console.log(result)
    // return "Todo Bien"
  }
  if(result == "granted"){
    let theToken = await fetchToken(setTokenFound)
    if(theToken != ""){
      requestSaveToken(theToken)
    }
  }
  return ""
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
