import { Grid, Typography, Button, TextField, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AlarmIcon from '@mui/icons-material/Alarm';
import IconButton from '@mui/material/IconButton';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TemporalService } from "../../../components/services/temporalService";
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AgregarEditarPersonalModal = ({
  statusModal,
  title,
  userData,
  handleClose,
  handleEmmit,
}) => {
  console.log("STATUSMODAL", statusModal)
  console.log("USERDATA", userData)
  const [page, setPage] = React.useState(0)
  const [nameData, setNameData] = React.useState((userData == undefined ? '' : userData?.name))
  const [lastNameData, setLastNameData] = React.useState((userData == undefined ? '' : userData?.lastName))
  const [rol, setRol] = React.useState(userData?.roles == undefined ? '' : userData?.roles[0])
  const [username, setUsername] = React.useState()
  const [id, setId] = React.useState((userData == undefined ? 0 : userData?.id))
  const [ isLoading, setIsLoading] = useState(false)
  const [estado, setEstado] = React.useState((userData == undefined ? '' : userData?.estado))
  const [roles, setRoles] = useState([])
  const [datePickerFinalValue, setDatePickerFinalValue] = useState()
  const [fechaNacimiento, setFechaNacimiento] = useState()
  const [nombre, setNombre] = useState(userData?.nombre)
  const [paterno, setPaterno] = useState(userData?.apellido_paterno)
  const [materno, setMaterno] = useState(userData?.apellido_materno)
  const [usuario, setUsuario] = useState(userData?.usuario?.name)
  const [documentoIdentidad, setDocumentoIdentidad] = useState(userData?.numero_documento)
  const [extension, setExtension] = useState(userData?.extension)
  const [celular, setCelular] = useState(userData?.nro_celular)
  const [flagEdicionCreacion, setFlagEdicionCreacion] = useState(false)
  const [idUsuario, setIdUsuario] = useState()



  const descriptionElementRef = React.useRef(null);

  const saveUserRequest = async () => {
    return true
  }
  const handleSave = async () => {
    storeUserRequest()
  }
  const handleSelectRol = async (event) => {
    console.log(event.target)
    
    setRol(event.target.value)
    if(!userData)
      userData = {}
    userData.rol = event.target.value
  }

  function timeOut(delay){
    return new Promise(res => setTimeout(res, delay))
  }

  const columns = [
    { id: 'nro', label: 'Nro.' }, //minWidth: 170
    { id: 'name', label: 'Nombre Completo' },
    { id: 'rol', label: 'Rol' },
    { id: 'actions', label: 'Acciones' },
  ]

  const handleActionUser = async (user) => {
    console.log(user)
    alert("Hello World")
  }
  const storeUserRequest = async () => {
    setIsLoading(true)
    const body =  {
      "nombre": nombre,
      "apellido_paterno": paterno,
      "apellido_materno": materno,
      "documento_identidad": documentoIdentidad,
      "extension": extension,
      "fecha_nacimiento": fechaNacimiento,
      "nro_celular": celular,
      "usuario": usuario,
      "tipo": rol
    }
    let url = ""
    let method = ""
    if(!flagEdicionCreacion){
      url = `user/edit/admin/${idUsuario}`
      method = "patch"
    }else{
      url = "user/create/admin/"
      method = "post"
    }
    try{
      let result = await TemporalService.sessionRequest({
        method: method,
        url: url,
        headers: null,
        body: body,
        param: null,
      })
      console.log("Respuesta de la Consulta Registro Usuario -->",result.data)
      handleEmmit("Usuario registrado exitosamente", "SUCCESS")
      handleClose()
      // setIssuesData(result.data)
    }catch(e){
      // setEmmitMessage("Error al obtener los roles")
      console.log("Error de consultar los roles --> ", e.response)
      if(e.response.data){
        let mensaje = e.response.data.mensaje + " \n "
        if(e.response.data.result){
          const objErrores = e.response.data.result
          for(var key of Object.keys(objErrores)){
            mensaje += " " + objErrores[key][0] + " \n "
          }
          // deberiaSerUnArray.forEach((element, key) => {
          //   mensaje += key + " " + element[0] + "\n"
          // });
        }
        handleEmmit(mensaje, "ERROR")
      }else{
        handleEmmit("Error al consultar los roles", "ERROR")  
      }

    }finally{
      setIsLoading(false)
    }
  }

  const rolRequest = async() => {
    setIsLoading(true)
    setRoles([])
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "user/get/rol",
        headers: null,
        body: null,
        param: null,
      })
      console.log("Respuesta de la Consulta de roles -->",result.data)
      // setIssuesData(result.data)
      setRoles(result.data)
    }catch(e){
      // setEmmitMessage("Error al obtener los roles")
      console.log("Error de consultar los roles --> ", e)
    }finally{
      setIsLoading(false)
    }
  }

  const setInitialParameters = async () => {
    setRol(userData?.roles == undefined || userData?.roles.length < 1  ? '' : userData?.roles[0])    
    setNombre(userData?.nombre)
    setPaterno(userData?.apellido_paterno)
    setMaterno(userData?.apellido_materno)
    setUsuario(userData?.usuario?.name)
    setDocumentoIdentidad(userData?.numero_documento)
    setExtension(userData?.extension)
    setCelular(userData?.nro_celular)
    setFechaNacimiento(userData?.fecha_nacimiento)
    setDatePickerFinalValue(userData ? userData.fecha_nacimiento : '')
    console.log("Usuario de los parametros iniciales-->", userData)
    setFlagEdicionCreacion(userData == undefined)
    setIdUsuario(userData?.usuario_id)
  }
  useEffect(() => {
    console.log("VERIFICANDO QUE SE ESTA ACTUALIZANDO CORRECTAMENTE")
    setInitialParameters()
  }, [userData])

  useEffect(() => {
    rolRequest()
  }, [])
  
  return (
    <div>
      <Dialog
        open={statusModal}
        onClose={handleClose}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          { title }
          {
            flagEdicionCreacion && <FormHelperText id="helper-creacion" ><br />La contraseña del usuario sera el Documento de Identidad hasta que se realice el cambio.</FormHelperText>
          }
        </DialogTitle>
        
        <DialogContent dividers={true}>
          
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="name" 
                    label="Nombre:" 
                    value = {nombre}
                    variant="outlined" 
                    onChange={(event) => {
                      setNombre(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="lastname" 
                    label="Apellido Paterno:" 
                    value = {paterno}
                    variant="outlined" 
                    onChange={(event) => {
                      setPaterno(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="apellido_materno" 
                    label="Apellido Materno:" 
                    value = {materno}
                    variant="outlined" 
                    onChange={(event) => {
                      setMaterno(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="username" 
                    label="Usuario de Ingreso al Sistema:" 
                    value = {usuario}
                    variant="outlined" 
                    onChange={(event) => {
                      setUsuario(event.target.value)
                    }}
                  />
                </Grid>
                
                <Grid item xs={4}>
                  <TextField 
                    disabled = {isLoading}
                    id="documento_identidad" 
                    label="Documento de Identidad:" 
                    value = {documentoIdentidad}
                    variant="outlined" 
                    onChange={(event) => {
                      setDocumentoIdentidad(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField 
                    disabled = {isLoading}
                    id="extension" 
                    label="Extension:" 
                    value = {extension}
                    variant="outlined" 
                    onChange={(event) => {
                      setExtension(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DatePicker
                      label="Fecha de Nacimiento"
                      value={datePickerFinalValue}
                      key="fecha-finalizacion"
                      aria-describedby="helper-finalizacion"
                      onChange={(newFecha) => {
                        // setFechaFinalizacion(newFecha.format('DD-MM-YYYY'))
                        setDatePickerFinalValue(newFecha)
                        setFechaNacimiento(newFecha.format('YYYY-MM-DD'))
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {/* <FormHelperText id="helper-finalizacion" error={errorFechaFinalizacion}>Fecha requerida</FormHelperText> */}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField 
                    disabled = {isLoading}
                    id="nro_celular" 
                    label="Nro. de Celular:" 
                    value = {celular}
                    variant="outlined" 
                    onChange={(event) => {
                      setCelular(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="select-departmemts-label">Rol</InputLabel>
                    <Select
                      labelId="select-departmemts-label"
                      id="select-departmemts"
                      key="select-departmemts"
                      label="Ciudad"
                      aria-describedby="helper-ciudad"
                      value={rol}
                      onChange={handleSelectRol}
                    >
                      {
                        roles.map((rol, index) => {
                          return <MenuItem key={`ciudad-${index}-${rol.id}`} value={rol.id}>{rol.nombre}</MenuItem>
                        })
                      }
                    </Select>
                    {/* <FormHelperText id="helper-ciudad" error={errorCiudad}>La ciudad es requerida</FormHelperText> */}
                  </FormControl>
                </Grid>
              </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled = {isLoading} onClick={handleClose}>Cancelar</Button>
          <Button disabled = {isLoading} onClick={handleSave}>{ flagEdicionCreacion ? 'Guardar':'Editar' }</Button>
        </DialogActions>
      </Dialog>
    </div>
    
  )
}
export default AgregarEditarPersonalModal
