import { Grid, Typography, Button, TextField, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Alarm';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { Service } from "../../components/services/service";
import { TemporalService } from "../../components/services/temporalService";
import LinearProgress from '@mui/material/LinearProgress';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Input } from '@mui/material';
import { FormHelperText } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalDetallePoliza from "./ui/modalDetallePoliza"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import GradingIcon from '@mui/icons-material/Grading';
import CircularProgress from '@mui/material/CircularProgress';
import e from "cors";


const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Emitir = () => {
  const [usersData, setUsersData] = useState([])
  
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusModalPersonal, setModalPersonal] = useState(false)
  const [codeIssue, setCodeIssue] = useState()
  const [issuesData, setIssuesData] = useState([])
  const [tableData, setTableData] = useState([])
  const [emmitError, setEmmitError] = useState()
  const [showEmmitError, setShowEmmitError] = useState(false)
  const [emmitMessage, setEmmitMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [polizaData, setPolizaData] = useState()
  const [modalDetailPoliza, setModalDetailPoliza] = useState(false)
  const [showEmmitSuccess, setShowEmmitSuccess] = useState(false)
  
  const handleRechazar = (data, motivo) => {
    rechazoRequest(data, motivo)    
  }
  

  const handleClose = () => {
    setShowEmmitError(false)
    setShowEmmitSuccess(false)
  }
  
  const handleChangePage = (event, newPage) => {
    console.log("Nueva Pagina", newPage)
    setPage(newPage);
  };

  const setIssuesEvent = () => {
    const start = page * rowsPerPage
    const final = (page + 1) * rowsPerPage
    setTableData(issuesData.slice(start, final))
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseModal = async () => {
    setModalDetailPoliza(false)
  }

  const handleDetailPolizaViewer = async(polizaDataModal) => {
    setPolizaData(polizaDataModal)
    await timeOut(1000)
    setModalDetailPoliza(true)
  }

  const handleFindByQuery = async () => {
    findRequest()
    // setLoading(true)
    // const query = codeIssue == undefined ? '' : codeIssue
    // const tableDataTemporaly = issuesData.filter((data) => {
    //   return (query != "" ? (
    //     data.automovil.valor_asegurado == query || 
    //     data.monto_pago == query || 
    //     data.automovil.ciudad.nombre_ciudad == query || 
    //     data.automovil.modelo.nombre_marca == query || 
    //     data.automovil.modelo.nombre_modelo == query || 
    //     data.automovil.placa == query || 
    //     data.datos_personales.nombre == query || 
    //     data.datos_personales.apellido_paterno == query || 
    //     data.datos_personales.apellido_materno == query || 
    //     data.estado_revision == query || 
    //     (new Date(data.vigencia_inicio)).toLocaleDateString("en-GB") == query || 
    //     (new Date(data.vigencia_final)).toLocaleDateString("en-GB") == query
    //     ) : true) 
    // })
    // setTableData(tableDataTemporaly)
    // setPage(0)
    // setRowsPerPage(10)
    // await timeOut(1000)
    // setLoading(false)
  }

  function timeOut(delay){
    return new Promise(res => setTimeout(res, delay))
  }
  
  const columns = [
    { id: 'valor_asegurado', label: 'Valor Asegurado' }, //minWidth: 170
    { id: 'nombre_ciudad', label: 'Ciudad' },
    { id: 'nombre_marca', label: 'Marca' },
    { id: 'nombre_modelo', label: 'Modelo' },
    { id: 'placa', label: 'Placa' },
    { id: 'full_name', label: 'Usuario' },
    { id: 'estado_revision', label: 'Estado' },
    { id: 'vigencia_inicio', label: 'Vig. Inicio' },
    { id: 'vigencia_final', label: 'Vig. Final' },
    { id: 'acciones', label: 'Acciones' },
  ]

  const handleActionEmmit = async (data) => {
    emmitRequest(data)
  }
  const findRequest = async () => {
    setLoading(true)
    setTableData([])
    setPage(0)
    setTotal(0)
    const param = {
      consulta: codeIssue,
      per_page: rowsPerPage,
      page: page
    }
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "poliza/list/paginated/",
        headers: null,
        body: null,
        param: param,
      })
      console.log(result.data)
      // setIssuesData(result.data)
      setTableData(result.data.results)
      setTotal(result.data.count)
    }catch(e){
      setEmmitMessage("Error al realizar la búsqueda")
    }finally{
      setLoading(false)
    }
  }

  const issueRequest = async () => {
    setLoading(true)
    setTableData([])
    const param = {
      consulta: codeIssue,
      per_page: rowsPerPage,
      page: page,
    }
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "poliza/list/paginated/",
        headers: null,
        body: null,
        param: param,
      })
      console.log("REsultado de la consulta de los datos")
      console.log(result)
      setTotal(result.data.count)
      setIssuesData(result.data.results)
      setTableData(result.data.results)
    }catch(e){
      setEmmitMessage("Error al obtener la lista de pólizas")
    }finally{
      setLoading(false)
    }
  }

  const emmitRequest = async (data) => {
    setLoading(true)
    const temporalData = tableData
    setTableData([])
    try{
      
      
      const response = await TemporalService.sessionRequest({
        method: "get",
        url: `alianza/emitir/?id_poliza=${data.id}`,
        headers: null,
        body: null,
        param: null,
      })
      if(response.data.detail.lstErrors.length > 0){
        let error = ""
        response.data.detail.lstErrors.forEach(element => {
          error += " " + element.sMessaged
        });
        setEmmitError(error)
        setEmmitMessage(error)
        setShowEmmitSuccess(false)
        setShowEmmitError(true)
      }else{
        setEmmitMessage("la poliza se emitió correctamente")
        setShowEmmitSuccess(true)
        setShowEmmitError(false)
        await inpeccionRequest(data, false)
        await documentsRequest(data, false)
        issueRequest()
      }
      
    }catch(e){
      let mensaje = "Error al registrar la emisión de la poliza"
      if(e.response.data){
        mensaje = e.response.data.message
      }
      setEmmitMessage(mensaje)
      setShowEmmitSuccess(false)
      setShowEmmitError(true)
      console.log("Error en la emission de la poliza::",e)
    }finally{
      setLoading(false)
      setTableData(temporalData)
    }
  }

  const rechazoRequest = async (data, motivo) => {
    try{  
      const body = {
        "motivo" : motivo
      }
      const response = await TemporalService.sessionRequest({
        method: "post",
        url: `poliza/rechazar/${data.id}`,
        headers: null,
        body: body,
        param: null,
      })
      console.log("Repuesta del rechazo",response)
      if(response.data.error != 0){
        let error = "Ocurrió un error al registrar el rechazo de la poliza, porfavor intente nuevamente."
        setEmmitMessage(error)
        setShowEmmitSuccess(false)
        setShowEmmitError(true)
      }else{
        setEmmitMessage("La poliza fue rechazada correctamente.")
        setShowEmmitSuccess(true)
        setShowEmmitError(false)
        issueRequest()
        handleCloseModal()
      }
      
    }catch(e){
      let mensaje = "Error al registrar el rechazo de la poliza"
      if(e.response.data){
        mensaje = e.response.data.message
      }
      setEmmitMessage(mensaje)
      setShowEmmitSuccess(false)
      setShowEmmitError(true)
      console.log("Error el rechazo de la poliza::",e)
    }finally{
      setLoading(false)
    }
  }

  const inpeccionRequest = async (data, sw= true) => {
    if(sw) setLoading(true)
    const temporalData = tableData
    setTableData([])
    try{
      const response = await TemporalService.sessionRequest({
        method: "get",
        url: `alianza/inspeccion/?id_poliza=${data.id}`,
        headers: null,
        body: null,
        param: null,
      })
      console.log("respuesta de la inspección",response)
      if(response.data.status != 1){
        let error = response.data.message
        setEmmitError(error)
        alert(error)
        setEmmitMessage(error)
        setShowEmmitSuccess(false)
        setShowEmmitError(true)
      }else{
        setEmmitMessage("La inspección se registro correctamente")
        setShowEmmitSuccess(true)
        setShowEmmitError(false)
        if(sw) setLoading(false)
        return true
      }
    }catch(e){
      setEmmitMessage("Error al consumir el servicio de Alianza, porfavor verifique su conexión a internet o contacte con el administrador del sistema.")
      setShowEmmitSuccess(false)
      setShowEmmitError(true)
      console.log(e)
    }finally{
      if(sw) setLoading(false)
      issueRequest()
    }
    return false
  }

  const documentsRequest = async (data, sw=true) => {
    if(sw) setLoading(true)
    const temporalData = tableData
    setTableData([])
    try{
      const response = await TemporalService.sessionRequest({
        method: "get",
        url: `alianza/documentos/?id_poliza=${data.id}`,
        headers: null,
        body: null,
        param: null,
      })
      console.log("respuesta de la documentación",response)
      if(response.data.status != 1){
        let error = response.data.message
        setEmmitMessage(error)
        setShowEmmitError(true)
        setShowEmmitSuccess(false)
        setEmmitError(error)
        console.log("Error interno")        
      }else{
        setEmmitMessage("La documentación se registro correctamente.")
        setShowEmmitSuccess(true)
        setShowEmmitError(false)
        if(sw) setLoading(false)
        return true
      }
    }catch(e){
      setEmmitMessage("Error al consumir el servicio de Alianza, porfavor verifique su conexión a internet o contacte con el administrador del sistema.")
      setShowEmmitSuccess(false)
      setShowEmmitError(true)
      console.log("ERROR CATCH ==> ",e)
    }finally{
      if(sw) setLoading(false)
      // setTableData(temporalData)
      issueRequest()
    }
    return true
  }


  useEffect(()=> {
    // setIssuesEvent()
    issueRequest()
  }, [ page, rowsPerPage])

  useEffect(() => {
    issueRequest()
  }, [])
  
  return (
    <Grid container direction="column" rowSpacing={3}>
      {/* <Grid item xs={12}>
        <Typography align="left">Usuario:</Typography>
      </Grid> */}
      { 
        <Snackbar open={showEmmitError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar open={showEmmitSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      <Box
        sx={{
          width: 40,
          height: 40,
        }}
      />
      <Typography component={'span'} variant="h3" align="left">
        Emisión de Pólizas
      </Typography>
      <Grid container direction="row" rowSpacing={3} alignItems={'center'}>
      <Grid item >
          <TextField 
            id="emision" 
            label="Criterio:" 
            variant="outlined" 
            onChange={(event)=> {
              setCodeIssue(event.target.value)
            }}
          />
      </Grid>
      <Grid item xs={3} md={3} >
          <Button variant="contained" onClick={handleFindByQuery}>Buscar</Button>
      </Grid>
      </Grid>

      <Grid item xs={12} >
        <Box sx={{ position:"relative" }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer >
            {
              loading && <LinearProgress />
            }
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography component={'span'} variant="columnTittle">
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={`${data.id}-${index}-row`}>
                        <TableCell key={`${data.id}-${index}-valor-asegurado`}>
                          {data.automovil.valor_asegurado}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-ciudad`}>
                          {data.automovil.ciudad.nombre_ciudad}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-marca`}>
                          {data.automovil.modelo.nombre_marca}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-modelo`}>
                          {data.automovil.modelo.nombre_modelo}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-placa`}>
                          {data.automovil.placa}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-usuario`}>
                          {`${data.datos_personales ? data.datos_personales.nombre : ''} ${data.datos_personales ? data.datos_personales.apellido_paterno : ''} ${data.datos_personales? data.datos_personales.apellido_materno : ''}`}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-estado`}>
                          {data.estado_revision}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-vigencia-inicio`}>
                          {data.vigencia_inicio}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-vigencia-final`}>
                          {data.vigencia_final}
                        </TableCell>
                        <TableCell key={`${data.id}-${index}-acciones`}>
                          <Stack spacing={2} direction="column">
                            {
                              data.estado_revision == "PENDIENTE" && 
                              <Button variant="contained" onClick={()=> {handleActionEmmit(data)}}>
                                Emitir
                              </Button>
                              // <IconButton color="primary" aria-label="Accion" component="label" onClick={()=> {handleActionEmmit(data)}}>
                              //   Emitir
                              // </IconButton>
                            }
                            {
                              data.estado_revision == "APROBADO" && !data.envio_documentos && 
                              <Button variant="contained" onClick={()=> {documentsRequest(data)}}>
                                Documentos
                              </Button>
                              // <IconButton color="primary" aria-label="Accion" component="label" onClick={()=> {documentsRequest(data)}}>
                              //   <ContentPasteGoIcon  />
                              // </IconButton>
                            }
                            {
                              data.estado_revision == "APROBADO" && !data.envio_inspeccion && 
                              <Button variant="contained" onClick={()=> {inpeccionRequest(data)}}>
                                Inspección
                              </Button>
                              // <IconButton color="primary" aria-label="Accion" component="label" onClick={()=> {inpeccionRequest(data)}}>
                              //   <GradingIcon  />
                              // </IconButton>
                            }
                            <IconButton color="primary" aria-label="Accion" component="label" onClick={()=> {handleDetailPolizaViewer(data)}}>
                              <VisibilityIcon   />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Filas por pagina:"
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        </Box>
      </Grid>
      <ModalDetallePoliza 
        statusModal = {modalDetailPoliza} 
        polizaParam = {polizaData}
        handleClose = {handleCloseModal}
        handleRechazo = {handleRechazar}
      >
      </ModalDetallePoliza>
    </Grid>
  )
}
export default Emitir