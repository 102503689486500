import { Grid, Typography, Button, TextField, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TemporalService } from "../../../components/services/temporalService";
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";
import { FormHelperText } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const NuevoEditarCompaniaModal = ({
  statusModal,
  companiaData,
  handleClose,
  handleEmmit,
}) => {
  const [username, setUsername] = React.useState()
  const [ isLoading, setIsLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [datePickerFinalValue, setDatePickerFinalValue] = useState()
  const [fechaNacimiento, setFechaNacimiento] = useState()
  const [flagEdicionCreacion, setFlagEdicionCreacion] = useState(false)
  const [idUsuario, setIdUsuario] = useState()

  const [errorNombreCompania, setErrorNombreCompania] = useState('')
  const [errorLogotipo, setErroLogotipo] = useState('')
  const [errorActivo, setErrorActivo] = useState('')

  const [id, setId] = useState('')
  const [nombreCompania, setNombreCompania] = useState('')
  const [logotipo, setLogotipo] = useState('')
  const [activo, setActivo] = useState(false)
  const [estado, setEstado] = useState("")
  const [image, setImage] = useState()
  

  const descriptionElementRef = React.useRef(null);

  const saveUserRequest = async () => {
    return true
  }
  const handleSave = async () => {
    storeCompaniaRequest()
  }
  const handleImage = async (event) => {
    console.log("Esta es la imagen que se esta subiendo-->",event.target.files[0])
    setImage(event.target.files[0])
    setLogotipo(URL.createObjectURL(event.target.files[0]));
  }

  const handleEstado = (activo) => {
    if(activo)
      setEstado("ACTIVO")
    else
      setEstado("INACTIVO")
  }

  const handleClosing = () => {
    setId("")
    setNombreCompania("")
    setLogotipo("")
    setActivo(false)
    handleEstado(false)
    handleClose()
  }

  const handleActionUser = async (user) => {
    console.log(user)
    alert("Hello World")
  }
  
  const storeCompaniaRequest = async () => {
    setIsLoading(true)
    const body = new FormData();
    body.append("image", image)
    body.append("nombre_compania",nombreCompania)
    body.append("logotipo", logotipo)
    body.append("activo", activo)
    console.log("Se envia los siguientes datos:", body)
    let url = ""
    let method = ""
    if(!flagEdicionCreacion){
      url = `compania/update/con/imagen/${id}`
      method = "patch"
    }else{
      url = "compania/create/con/imagen"
      method = "post"
    }
    try{
      let result = await TemporalService.sessionRequest({
        method: method,
        url: url,
        headers: null,
        body: body,
        param: null,
      })
      console.log("Respuesta de la Consulta Registro Compania -->",result.data)
      handleEmmit("Compañía registrada exitosamente", "SUCCESS")
      handleClosing()
      // setIssuesData(result.data)
    }catch(e){
      // setEmmitMessage("Error al obtener los roles")
      console.log("Error de registro o edición de Compania --> ", e.response)
      if(e.response.data){
        let mensaje = e.response.data.mensaje + " \n "
        if(e.response.data.detalle){
          const objErrores = e.response.data.detalle
          for(var key of Object.keys(objErrores)){
            if(key == "image")
              setErroLogotipo(objErrores[key])
            if(key == "nombre_compania")
              setErrorNombreCompania(objErrores[key])
            if(key == "activo")
              setErrorActivo(objErrores[key])
          }
        }
        handleEmmit(mensaje, "ERROR")
      }else{
        handleEmmit("Error al registrar los datos", "ERROR")  
      }
    }finally{
      setIsLoading(false)
    }
  }

  const setInitialParameters = async () => {

    setId(companiaData ? companiaData?.id : "")
    setNombreCompania(companiaData? companiaData.nombre_compania : "")
    setLogotipo(companiaData ? companiaData?.logotipo : "")
    setActivo(companiaData ? companiaData?.activo : false)
    handleEstado(companiaData ? companiaData?.activo : false)

    if(companiaData)
      setFlagEdicionCreacion(false)
    else
      setFlagEdicionCreacion(true)
  }
  useEffect(() => {
    setInitialParameters()
  }, [companiaData])

  useEffect(() => {
  }, [])
  
  return (
    <div>
      <Dialog
        open={statusModal}
        onClose={handleClosing}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edición de Ciudad</DialogTitle>
        <DialogContent dividers={true}>
          
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField 
                    error = {errorNombreCompania.length > 0}
                    disabled = {isLoading}
                    id="nombreCompania" 
                    label="Nombre:" 
                    value = {nombreCompania}
                    variant="outlined" 
                    helperText={errorNombreCompania}
                    onChange={(event) => {
                      setNombreCompania(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel 
                    control={
                      <Switch
                        error = {errorActivo.length > 0}
                        checked={activo}
                        helperText={errorActivo}
                        onChange={(event) => {
                          setActivo(event.target.checked)
                          handleEstado(event.target.checked)
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    } 
                    label = {estado}
                  />
                  
                </Grid>

                <Grid item xs={6}>
                  <div>
                    <img
                      src={`${logotipo}`}
                      alt="Imagen de Compañía"
                      width="120px"
                    />
                  </div>
                <Button variant="contained" component="label">
                  Subir Imagen
                  <input hidden accept="image/*" multiple type="file" onChange={handleImage} />
                </Button>
                {
                  errorLogotipo.length > 0 && <FormHelperText error id="helper-creacion" ><br />{errorLogotipo}</FormHelperText>
                }
                </Grid>
              </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled = {isLoading} onClick={handleClosing}>Cancelar</Button>
          <Button disabled = {isLoading} onClick={handleSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </div>
    
  )
}
export default NuevoEditarCompaniaModal