import { Grid, Typography, Button, TextField, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TemporalService } from "../../../components/services/temporalService";
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const NuevoEditarTasaModal = ({
  statusModal,
  tasaData,
  title,
  handleClose,
  handleEmmit,
}) => {
  const [username, setUsername] = React.useState()
  const [ isLoading, setIsLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [datePickerFinalValue, setDatePickerFinalValue] = useState()
  const [fechaNacimiento, setFechaNacimiento] = useState()
  const [flagEdicionCreacion, setFlagEdicionCreacion] = useState(false)
  const [idUsuario, setIdUsuario] = useState()
  const [errorDays, setErrorDays] = useState("")
  const [errorPurchaseNumber, setErrorPurchaseNumber] = useState("")
  const [errorRate, setErrorRate] = useState("")


  const [purchaseNumber, setPurchaseNumber] = useState()
  const [days, setDays] = useState()
  const [rate, setRate] = useState()
  const [idTasa, setIdTasa] = useState()



  const descriptionElementRef = React.useRef(null);

  const saveUserRequest = async () => {
    return true
  }

  const handleClosing = async() => {
    setErrorDays("")
    setErrorPurchaseNumber("")
    setErrorRate("")
    setPurchaseNumber()
    setDays()
    setRate()
    setIdTasa()
    handleClose()
  }
  const handleSave = async () => {
    storeTasaRequest()
  }

  const handleActionUser = async (user) => {
    console.log(user)
    alert("Hello World")
  }
  
  const storeTasaRequest = async () => {
    setIsLoading(true)
    const body =  {
      "purchase_number" : purchaseNumber,
      "days" : days,
      "rate" : rate,
    }
    let url = ""
    let method = ""
    if(!flagEdicionCreacion){
      url = `rates/update/${idTasa}`
      method = "patch"
    }else{
      url = "rates/create"
      method = "post"
    }
    try{
      let result = await TemporalService.sessionRequest({
        method: method,
        url: url,
        headers: null,
        body: body,
        param: null,
      })
      console.log("Respuesta de la Consulta Registro Tasa -->",result.data)
      handleEmmit("Tasa registrada exitosamente", "SUCCESS")
      handleClosing()
      // setIssuesData(result.data)
    }catch(e){
      // setEmmitMessage("Error al obtener los roles")
      console.log("Error de registro o edición de tasa --> ", e.response)
      if(e.response.data){
        let mensaje = "Error al realizar el registro, verifique el formulario porfavor."
        if(e.response.data){
          const objErrores = e.response.data
          for(var key of Object.keys(objErrores)){
            if(key == "days")
              setErrorDays(objErrores[key][0])
            if(key == "purchase_number")
              setErrorPurchaseNumber(objErrores[key][0])
            if(key == "rate")
              setErrorRate(objErrores[key][0])
          }
          // deberiaSerUnArray.forEach((element, key) => {
          //   mensaje += key + " " + element[0] + "\n"
          // });
        }
        handleEmmit(mensaje, "ERROR")
      }else{
        handleEmmit("Error al registrar los datos", "ERROR")  
      }
    }finally{
      setIsLoading(false)
    }
  }

  const setInitialParameters = async () => {
    console.log("Esta es la tasa para actualizar-->", tasaData)
    setPurchaseNumber(tasaData?.purchase_number)
    setDays(tasaData?.days)
    setRate(tasaData?.rate)
    setIdTasa(tasaData?.id)

    if(tasaData)
      setFlagEdicionCreacion(false)
    else
      setFlagEdicionCreacion(true)
  }
  useEffect(() => {
    setInitialParameters()
  }, [tasaData])

  useEffect(() => {
  }, [])
  
  return (
    <div>
      <Dialog
        open={statusModal}
        onClose={handleClosing}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true}>
          
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField 
                    error={errorPurchaseNumber.length > 0}
                    disabled = {isLoading}
                    id="purchase_number" 
                    label="Nro. de Renovaciones:" 
                    value = {purchaseNumber}
                    variant="outlined" 
                    helperText={errorPurchaseNumber}
                    onChange={(event) => {
                      setPurchaseNumber(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    error={errorDays.length > 0}
                    disabled = {isLoading}
                    id="days" 
                    label="Cant. de días:" 
                    value = {days}
                    variant="outlined" 
                    helperText={errorDays}
                    onChange={(event) => {
                      setDays(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField 
                    error={errorRate.length > 0}
                    disabled = {isLoading}
                    id="rate" 
                    label="Tasa:" 
                    value = {rate}
                    variant="outlined" 
                    helperText={errorRate}
                    onChange={(event) => {
                      setRate(event.target.value)
                    }}
                  />
                </Grid>
              </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled = {isLoading} onClick={handleClosing}>Cancelar</Button>
          <Button disabled = {isLoading} onClick={handleSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </div>
    
  )
}
export default NuevoEditarTasaModal