import React from "react";
import { Line } from "react-chartjs-2";

function LineChart({ chartData, subTitle, title }) {
  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      <Line
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: subTitle
            },
            legend: {
              display: true
            }
          }
        }}
      />
    </div>
  );
}

export default LineChart;
