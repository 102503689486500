import { Grid, Typography, Button, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Alarm';
import IconButton from '@mui/material/IconButton';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { TemporalService } from "../../components/services/temporalService";
// import AgregarEditarPersonalModal from "./ui/agregarEditarPersonalModal";
import EditarCiudadModal from "./ui/modalEditarCiudad"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// // Prueba de Consumo de apis de google
// const { google } = require('googleapis')
// const scopes = "https://www.googleapis.com/auth/analytics.readonly"
// const jwt = new google.auth.JWT(
//   process.env.CLIENT_EMAIL,
//   null,
//   process.env.PRIVATE_KEY.replace(/\\n/g, "\n"),
//   scopes
// )

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Ciudad = () => {
  const [ciudadData, setCiudadData] = useState([])
  const [ciudadDataEdit, setCiudadDataEdit] = useState()
  const [loading, setLoading] =useState(false)
  const [query, setQuery] = useState("")

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusModalPersonal, setModalPersonal] = useState(false)
  const [total, setTotal] = useState(0)
  const [ title, setTitle ] = useState("")
  console.log(statusModalPersonal)
  const [showModal, setShowModal] = useState(false)
  const [showEmmitError, setShowEmmitError] = useState(false)
  const [showEmmitSuccess, setShowEmmitSuccess] = useState(false)
  const [emmitMessage, setEmmitMessage] = useState("")

  
  const handleClose = () => {
    setShowEmmitError(false)
    setShowEmmitSuccess(false)
    setEmmitMessage("")
  }

  const handleEmmit = (message, type) => {
    setEmmitMessage(message)
    if(type == "ERROR"){
      setShowEmmitError(true)
      setShowEmmitSuccess(false)
    }else{
      getCiudadRequest()
      setShowEmmitError(false)
      setShowEmmitSuccess(true)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleCloseModal = async () => {
    // setModalPersonal(false)
    setShowModal(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCiudadRequest = async () => {
    setLoading(true)
    setCiudadData([])
    try{
      let result = await TemporalService.sessionRequest({
        method: "get",
        url: "ciudad/list/admin",
        headers: null,
        body: null,
        param: null,
      })
      console.log("Respuesta de la petición de CIUDADES")
      console.log(result.data)
      // setTotal(result.data.count)
      setCiudadData(result.data)
      // setClientData(result.data.results)
    }catch(e){

    }finally{
      setLoading(false)
    }
  }
  const columns = [
    // { id: 'nro', label: 'Nro.' }, //minWidth: 170
    { id: 'nombre_ciudad', label: 'Ciudad' },
    { id: 'id_alianza_ciudad', label: 'Id Alianza' },
    { id: 'usuario_aianza', label: 'Usuario' },
    { id: 'poliza_alianza_1', label: 'Póliza 1' },
    { id: 'poliza_alianza_2', label: 'Póliza 2' },
    { id: 'poliza_alianza_3', label: 'Póliza 3' },
    { id: 'actions', label: 'Acciones' },
  ]

  const handleActionUser = async (user) => {
    console.log(user)
    setCiudadDataEdit(user)
    setTitle("Edición de usuario")
    await timeOut(1000)
    setShowModal(true)
  }

  function timeOut(delay){
    return new Promise(res => setTimeout(res, delay))
  }

  useEffect(() => {
    getCiudadRequest()
  }, [])
  
  return (
    <Grid container direction="column" rowSpacing={3}>
      { 
        <Snackbar open={showEmmitError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {emmitMessage.split("\n").map((men) => {
              return (<span>{men}<br /></span>)
            })}  
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar open={showEmmitSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {emmitMessage}
          </Alert>
        </Snackbar>
      }
      {/* <Grid item xs={12}>
        <Typography align="left">Usuario:</Typography>
      </Grid> */}
      <Box
        sx={{
          width: 40,
          height: 40,
        }}
      />
      <Typography component={'span'} variant="h3" align="left">
        Listado de Ciudades
      </Typography>
      
      <Grid item xs={12} >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography component={'span'} variant="columnTittle">
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ciudadData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                        <TableCell key={`${data.id}-ciudad`}>
                          { data.nombre_ciudad }
                        </TableCell>
                        <TableCell key={`${data.id}-id-alianza`}>
                          {data.id_alianza_ciudad}
                        </TableCell>
                        <TableCell key={`${data.id}-usuario`}>
                          {data.usuario_alianza}
                        </TableCell>
                        <TableCell key={`${data.id}-poliza-1`}>
                          {data.poliza_alianza_1}
                        </TableCell>
                        <TableCell key={`${data.id}-poliza-2`}>
                          {data.poliza_alianza_2}
                        </TableCell>
                        <TableCell key={`${data.id}-poliza-3`}>
                          {data.poliza_alianza_3}
                        </TableCell>
                        <TableCell key={`${data.id}-acciones`}>
                          <Button variant="contained" onClick={()=> {handleActionUser(data)}}>
                            Editar
                          </Button>
                          {/* <IconButton color="primary" aria-label="Accion" component="label" onClick={()=> {handleActionUser(data)}}>
                            <EditIcon  />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={ciudadData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      <EditarCiudadModal
        statusModal = {showModal} 
        ciudadData = {ciudadDataEdit}
        handleClose = {handleCloseModal}
        handleEmmit = {handleEmmit}
      >
      </EditarCiudadModal>
    </Grid>
  )
}
export default Ciudad