import React, { useEffect, useState } from "react"
import Footer from "../ui/footer"
import Header from "../ui/header"
import MenuBar from "../ui/navbar"
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ThemeProvider } from "@mui/material"
import {colors, darktheme, ligththeme} from '../common/themes'
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie'

const ExternalLayout = (
  {children}
) => {
  const [cookie, removeCookie] = useCookies()
  const navigate = useNavigate();
  useEffect(() => {
    if(cookie.token != undefined && cookie.token != "undefined"){
      navigate("/dashboard");
    }
  })
    
  return <>
    <ThemeProvider theme={darktheme}>
      <CssBaseline />
      <Toolbar /> 
      <Toolbar /> 
      <Header title={'EXTERNO'} />
      {children}
      <Footer children={''} />
    </ThemeProvider>
  </>
}

export default ExternalLayout