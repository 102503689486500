import { Grid, Typography, Button, TextField, } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AlarmIcon from '@mui/icons-material/Alarm';
import IconButton from '@mui/material/IconButton';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TemporalService } from "../../../components/services/temporalService";
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ResetPasswordModal = ({
  statusModal,
  title,
  userData,
  handleClose,
  handleEmmit,
}) => {
  console.log("STATUSMODAL", statusModal)
  console.log("USERDATA", userData)
  const [page, setPage] = React.useState(0)
  const [nameData, setNameData] = React.useState((userData == undefined ? '' : userData?.name))
  const [lastNameData, setLastNameData] = React.useState((userData == undefined ? '' : userData?.lastName))
  const [rol, setRol] = React.useState(userData?.roles == undefined ? '' : userData?.roles[0])
  const [username, setUsername] = React.useState()
  const [id, setId] = React.useState((userData == undefined ? 0 : userData?.id))
  const [ isLoading, setIsLoading] = useState(false)
  const [estado, setEstado] = React.useState((userData == undefined ? '' : userData?.estado))
  const [roles, setRoles] = useState([])
  const [datePickerFinalValue, setDatePickerFinalValue] = useState()
  const [fechaNacimiento, setFechaNacimiento] = useState()
  const [nombre, setNombre] = useState(userData?.nombre)
  const [paterno, setPaterno] = useState(userData?.apellido_paterno)
  const [materno, setMaterno] = useState(userData?.apellido_materno)
  const [usuario, setUsuario] = useState(userData?.usuario?.name)
  const [documentoIdentidad, setDocumentoIdentidad] = useState(userData?.numero_documento)
  const [extension, setExtension] = useState(userData?.extension)
  const [celular, setCelular] = useState(userData?.nro_celular)
  const [idUsuario, setIdUsuario] = useState()

  const handleSave = async () => {
    changePasswordRequest()
  }

  const changePasswordRequest = async () => {
    setIsLoading(true)
    const body =  {
      "user_id" : idUsuario
    }
    try{
      let result = await TemporalService.sessionRequest({
        method: "post",
        url: "user/reset/password/admin/",
        headers: null,
        body: body,
        param: null,
      })
      console.log("Respuesta de la Consulta Registro Usuario -->",result.data)
      handleEmmit("Contraseña reestablecida exitosamente.", "SUCCESS")
      handleClose()
      // setIssuesData(result.data)
    }catch(e){
      // setEmmitMessage("Error al obtener los roles")
      console.log("Error de consultar los roles --> ", e.response)
      if(e.response.data){
        let mensaje = e.response.data.mensaje + " \n "
        if(e.response.data.result){
          const objErrores = e.response.data.result
          for(var key of Object.keys(objErrores)){
            mensaje += " " + objErrores[key][0] + " \n "
          }
          // deberiaSerUnArray.forEach((element, key) => {
          //   mensaje += key + " " + element[0] + "\n"
          // });
        }
        handleEmmit(mensaje, "ERROR")
      }else{
        handleEmmit("Error al consultar los roles", "ERROR")  
      }

    }finally{
      setIsLoading(false)
    }
  }

  const setInitialParameters = async () => {
    setRol(userData?.roles == undefined || userData?.roles.length < 1  ? '' : userData?.roles[0])    
    setNombre(userData?.nombre)
    setPaterno(userData?.apellido_paterno)
    setMaterno(userData?.apellido_materno)
    setUsuario(userData?.usuario?.name)
    setDocumentoIdentidad(userData?.numero_documento)
    setExtension(userData?.extension)
    setCelular(userData?.nro_celular)
    setFechaNacimiento(userData?.fecha_nacimiento)
    setDatePickerFinalValue(userData ? userData.fecha_nacimiento : '')
    console.log("Usuario de los parametros iniciales-->", userData)
    setIdUsuario(userData?.usuario_id)
  }
  useEffect(() => {
    setInitialParameters()
  }, [userData])

  useEffect(() => {
  }, [])
  
  return (
    <div>
      <Dialog
        open={statusModal}
        onClose={handleClose}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          { title }
          <FormHelperText id="helper-creacion" ><br />La contraseña del usuario sera el Documento de Identidad hasta que se realice el cambio.</FormHelperText>
        </DialogTitle>
        
        <DialogContent dividers={true}>
          
              <Grid container direction="row" spacing={2}>
                <Typography>Se realizara el cambio de contraseña para {nombre} {paterno} {materno} con nombre de usuario {usuario}
                <br />
                ¿Esta seguro de continuar?
                </Typography>
              </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled = {isLoading} onClick={handleClose}>Cancelar</Button>
          <Button disabled = {isLoading} onClick={handleSave}>Continuar</Button>
        </DialogActions>
      </Dialog>
    </div>
    
  )
}
export default ResetPasswordModal