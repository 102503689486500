import axios, {AxiosError} from "axios"
// import { Cookies, CookiesProvider } from 'react-cookie'
import Cookies from 'universal-cookie';

const basePath = process.env.REACT_APP_BACK_END_URL + "/api/"

export const correctStates = [200, 201, 202, 204]

class TemporalServiceClass {
  async sinAxios(){
    console.log("SINAXIOS")
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, DELETE',
        'Access-Control-Allow-Headers': 'Authorization',
      },
    };

    const result = fetch("http://localhost:9000/polizas", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    console.log(result)
  }

  async externalRequest({method = 'get', url, headers,  body, param}){
    try{
      // console.log("Desde la solicitud REQUEST!!!")
      let cookeando = new Cookies()
      // let provider = new CookiesProvider()
      let token = cookeando.get("token")
      if(token == undefined){
        token = ""
      }
      console.log("Resultado final TOKEN::", token)
      // console.log("Este es el cokeando:::>",cookeando)
      // console.log("Este es el provider:::>",CookiesProvider)
      // console.log("PRUEBAAAA::: ", cookeando.get("token"))
      // console.log("Este es el token:::>",token)
      const path = basePath + url
      return axios({
        method: method.toString(),
        url: path,
        headers: {
          'Content-Type': 'application/json' ,
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Authorization': 'Bearer los ositos son los mejores'
          // 'Access-Control-Allow-Origin': '*' ,
          // 'Access-Control-Allow-Headers' : true,
        },

        data: body,
        params: param,
        validateStatus: (status)=>{
          return correctStates.some((estate)=> estate === status )
        },
      })//timeout: 20000,
    }catch(e){
      console.log("Error en el request",e)
    }
  }


  async sessionRequest({method = 'get', url, headers,  body, param}) {
    try{
      // console.log("Desde la solicitud REQUEST!!!")
      let cookeando = new Cookies()
      // let provider = new CookiesProvider()
      let token = cookeando.get("token")
      if(token == undefined){
        token = ""
      }
      console.log("Resultado final TOKEN::", token)
      // console.log("Este es el cokeando:::>",cookeando)
      // console.log("Este es el provider:::>",CookiesProvider)
      // console.log("PRUEBAAAA::: ", cookeando.get("token"))
      // console.log("Este es el token:::>",token)
      const path = basePath + url
      return axios({
        method: method.toString(),
        url: path,
        headers: {
          'Content-Type': 'application/json' ,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': "Token " + token
          // 'Authorization': 'Bearer los ositos son los mejores'
          // 'Access-Control-Allow-Origin': '*' ,
          // 'Access-Control-Allow-Headers' : true,
        },

        data: body,
        params: param,
        validateStatus: (status)=>{
          return correctStates.some((estate)=> estate === status )
        },
      })//timeout: 20000,
    }catch(e){
      console.log("Error en el request",e)
    }
    
  }

  async get(url){
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch("http://181.188.186.158:8000/api/alianza/emitir/?id_poliza=288", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  isNetworkError(err) {
    return !!err.isAxiosError && !err.response
  }
}

export const TemporalService = new TemporalServiceClass()