export const Data = [
  {
    id: 1,
    year: "Enero",
    userGain: 80000,
    userLost: 823
  },
  {
    id: 2,
    year: "Febrero",
    userGain: 45677,
    userLost: 345
  },
  {
    id: 3,
    year: "Marzo",
    userGain: 78888,
    userLost: 555
  },
  {
    id: 4,
    year: "Abril",
    userGain: 90000,
    userLost: 4555
  },
  {
    id: 5,
    year: "Mayo",
    userGain: 4300,
    userLost: 234
  }
];
